.code-container-item .accordion-button::after {
   display: none !important;
}

.code-container-item [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: initial !important;
}
.code-container-for-kitchen [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer !important;
}
.code-container-item .voucher-accordian{
    border-radius: unset !important;
    border: unset !important;
}