.payment-form{
    margin-left: 25px !important; 
    margin-right: 20px !important;
}
.credit-c{
     margin-top: 40px; 
}
.image-card-master{
    margin-left: 3px ;
    width: 24px;
    height: 24px;
}
.image-card-visa{
    margin-left: 3px ;
    width: 24px;
    height: 24px;
}
.digits-card{
    display: inline-block;
}
.saved-cards{
    margin-bottom: 15px;
    font-weight: 600;
    margin-left: 27px;
}
.saved-cards-wrapper{
    display: grid;
    gap: 20px;
}
.btn-card-details{
    font-size: 14px;
    margin-top: 5px;
    margin-left: 25px;
    background-color: rgb(0, 122, 175) !important;
    border-color: rgb(0, 122, 175) !important;
}
.checkbox-card{
    width:30px;
    height:20px
}
.parent-checkbox{
    margin-left: 25px;
    margin-top: 30px;
}
.add-card{
    font-size:15px
}
.xpay-btn.btn.btn-primary{
    display: none;
}
.form-check-input-card{
    margin-left: 45px;
    height:1.3em !important ;
    width: 1.3em !important;
}
.add-card-details{
    margin-left: 10px;
}
.credit-debit{
    margin-left: 25px;
    margin-top: 25px;

}
@media screen and (max-width: 460px) {
    .card-options .existing-card{
        margin-left:0px !important;
        display: block !important;
    }
    .card-options{
        display: flex;
        align-items: center;
    }
    .save-card-details{
        display: block;
    }
  }
.add-new-card{
    margin-top: 3px !important;
    font-weight: 500;
    font-size: 16px;
}
@media screen and (max-width: 425px) {
    .form-check-input-card{
        height: 1em !important;
        width: 1em !important;

    }
  }
  @media screen and (min-width: 425px) {
    .card-options input[type="checkbox"]{
        padding: 10px;
    }
  }

@media screen and (max-width: 425px) {
    .deletebtnxpay{
        display: inline-block !important;
        margin-left: 30px !important;
    }
    
  }

.card-options .save-card-details p{
    margin-bottom: 0;
}
.card-options input[type="checkbox"]{
    margin-top: 0;
}
.saved-cards-wrapper .form-check-label{
    height: fit-content;
    display: flex !important;
    align-items: center;
    gap: 2px;
    line-height: unset !important;
    margin-left: 10px;
}
.saved-cards-wrapper p{
    margin-bottom: unset;
}

.modal-single-item-xpay {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: hsl(0, 0%, 40%);
}

.latestclasscheck{
    display: inline-block !important;

}
.xpaybtnprp{
    border: none !important;
    border-radius: 20px !important;
}

.firsthalfxpay{
    display: inline-block !important;
}
.deletebtnxpay{
    display: inline-block !important;
    margin-left: 110px;
}
.xpay-disabled{
    width: 100%;
    border: 0;
    margin-top: 15px;
    padding: 15px;
    font-weight: 600;
    border-radius: 5px;
    color: #fff;
    background-color:rgb(127 180 200) ;
    color: #fff;
    transition: all 0.3s;
    text-decoration: none;
    cursor:not-allowed !important;
}