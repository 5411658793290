

@media (max-width: 800px){
    .start-order-module a{
        font-size: 25px;
    }
    .or-item{
       top: 3px;
       margin-right: 15px;
    }
    .select-location {
        margin: unset !important;
        color: #454545 !important;
        font-size: 10px !important;
        font-weight: 700 !important;
        text-transform: uppercase !important;
        padding-inline: unset !important;
    }
    .delivering-to{
        font-size: 13px;
        font-family: "Helvetica Neue";
        font-weight: 400;
        margin: unset;
    }
}
@media (max-width: 700px){
    .start-order-module a:first-child{
        padding: 5px 30px;
    }
    .start-order-module a:last-child{
        padding: 5px 30px;
    }
    .start-order-module a{
        font-size: 16px;
        white-space: nowrap;
    }
    .or-item{
        display: none;
    }
}
@media (max-width: 700px){
    .start-order-module a:first-child{
        padding: 5px 10px;
    }
    .start-order-module a:last-child{
        padding: 5px 10px;
    }
}
@media (max-width: 767px){
    .cart-container{
        width: 380px !important;
    }
    .appsoore-qr{
        /* display: none; */
    }
    .appsoore-mobile-qr{
        /* display: block; */
    }
}

@media (max-width: 768px){
    .tailstyle-content .image-radio-box{
        padding-right: 46% !important;
    }
    .footer-container{
        flex-wrap: wrap;
        gap: 0px;

    }
    /* .card-header{
        width: 230px !important;
    } */
    .detail-order-cart{
        width: 100%;
      }
      .account-form .nav-link{
        font-size: 14px;
      }
      .order-card h4{
        font-size: 16px
      }
      .store-timing{
        font-size: 16px;
      }
    .banner-slider{
        width: 100%;
    }
    .panel-body{
        width: 75%;
    }
    .error-404 h5{
        width: 90%;
    }
}
@media (max-width: 991px){
    .skip-for-menu{
        display: block !important;
    }
    .menue-items{
        top: 117px;
    }
    .list-menu{
        justify-content: start;
    }
    .panel-body{
        width: 90%;
    }
    .corder-container{
        width: 90% !important;
    }
    /* .banner-col{
        padding: 0 !important;
    } */
    .card-header{
        width: 75% !important;
    }
    .offer-header{
        top: 56px;
    }
    .offers-items-container{
        top: 105px;
    }
    .pd-l-0{ 
        padding-left: 0 !important;
    }
    .scroll-to-top{
        bottom: 105px;
    }
    .product-modal{
        padding: 0 !important;
    }
}
@media (max-width: 1199px){
    .card-header{
        /* width: 140px; */
        font-size: 16px;
    }
    /* .menu-card{
        height: 340px;  
    } */
    .menu-img{
        height: 165px;
    }
    .validate-cart {
        height: 215px !important;
    }
    /* .add-legend-item{
        top: 42%;
    } */
    .nav-item{
        padding-inline: 14px ;
    }
    .brandLogo{
        margin-right: 30px !important;
    }
}
@media (max-width: 510px){
    .card-header{
        /* width: 160px !important; */
        font-size: 15px;
    }
    .corder-container{
        padding: 0 5px;
        margin-top: 20px;
        width: 100% !important;
    }
    .item-id-number{
        width: 170px;
    }
}
@media (max-width: 350px){
    .address-details-container {
		place-content: center;
		text-align: center;
	}

	.address-details-container .address-text {
		font-size: 12px;
	}

	#address-marker {
		width: 16px;
	}
}
@media screen and (min-width: 426px){
    .btn-item{
        top: 10.7rem
    } 
}
@media screen and (min-width: 1200px){
    .btn-item{
        top: 12.8rem
    } 
    .add-legend-item {
        top: 10.7rem
    }
}
@media (max-width: 425px){
    .btn-item{
        font-size: 18px;
    } 
    .validate-cart {
        height: 185px !important;
    }
    .topping-price {
        font-weight: 500;
        white-space: nowrap !important;
    }
    .produtc-photo {
        height: 250px;
    }
    /* .add-btn{
        margin-left: 6px;
    } */
    .card-text {
        -webkit-line-clamp: 2;
    }
    /* .login-form{
        width: 90%;
    } */
    .footer-box{
        width: 50%;
    }
    .terms-conditions p{
        font-size: 14px;
    }
    .banner-image{
        height: 400px;
        margin-bottom: 10px;
    }
    /* .banner-col{
        padding: 0 !important;
    } */
    .hero-banner{
        padding-top: 165px;
    }
    .stors-link{
        width: 120px;
    }
    .menue-header a{
       padding-inline: 12px;
    }
    .list-menu{
        justify-content: start;
    }
    .menue-header {
        justify-content: start;
    }
    .menue-items {
        top: 112px !important;
    }
    .menue-items a{
        font-size: 13px;
    }
    .price-order button{
        font-size: 13px;
        padding: 7px 10px;
    }
    /* .menu-card{
        height: 300px;
    } */
    .menu-img{
        height: 130px;
    }
    .offer-menu{
        height: 240px;
    }
    .contact-form{
        max-width: 90%;
    }
    .product-order{
        width: 100%;
        padding: 0 15px 70px;
    }
    .title-ordters span{
        font-size: 11px;
    }
    .order-progressbar{
        font-size: 12px;
    }
    .errow-none{
        display: none;
    }
    .pbtm-sm-5{
        padding-bottom: 20px;
    }
    .home-banner-slider{
        height: 350px;
        padding-left: 0;
    }
    .error-image{
        width: 280px;
    }
    .error-404 h4{
        font-size: 24px;
    }
    .menue-header{
        /* display: block; */
    }
    /* .btn-item{
        top: 46%;
    } */
    .legend-images {
        width: 30px;
    }
    .legend-img-container{
        width: 90%;
    }

    .ledend-title p {
        font-size: 12px;
    }
    .select-voucher .nav-item button{
        padding: 5px 30px;
    }
    /* .add-legend-item{
        top: 33%;
    } */
    .offer-heading{
        font-size: 20px;
    }
    .card-header{
        width: 65% !important;
    }
    .legend-img-container{
        width: 97%;
    }
    .about-ordre{
        width: 100%;
    }
    .cart-outer-quantity .quantity-btn{
        width: 21px;
        height: 21px;
        font-size: 15px;
    }
    i.ri-subtract-line{
        font-size: 15px;
    }
    i.ri-delete-bin-6-line {
        font-size: 15px !important;
    }
    .cart-container{
        width: 91% !important;
    }
    .nutritional-info{
        margin-top: 70px;
    }
    .nutritional-info h4{
        margin-left: 0;
    }
    .natureitem-title{
        font-size: 24px;
        border-bottom: 3px solid #000;
    }
    .menue-header{
        justify-content: flex-start;
    }
    .add-legend-item{
        top: 6.5rem;
    }

}
@media (max-width: 320px){
    /* .menu-card{
        height: 290px !important;
    } */
    .menu-img{
        height: 100px;
    }

    /* .btn-item {
        top: 40%;
    } */
    .ledend-title{
        gap: 5px;
    }
    .legend-detail h6 {
        font-size: 14px;
    }
    .select-voucher .nav-item button{
        padding: 5px 20px;
    }
    /* .add-legend-item{
        top: 27%;
    } */
    /* .card-text {
        -webkit-line-clamp: 1;
    } */
}
@media (max-width: 575px){
    .size-content{
        padding: 0 !important;
    }
    .domino-offers::before{
        left: -30px;
        bottom: -1px;
    }
    /* .product-order {
        top: 330px
    } */
    .produtc-photo {
        height: 300px;
    }
    .domino-offers::after{
        right: -33px !important;
        bottom: -1px;
    }
    .outer-order-type {
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 60px;
        padding: 0;
    }
    .past-order{
        gap: 15px;
        margin-top: 15px;
        display: flex;
        flex-direction: column  ;
    }
    .order-card{
        margin: 0;
        width: 100%;
    }
    .outer-order-type  button{
        width: 100%;
    }
    .wish-list-items{
        margin-top: 65px;
    }
    .offers-items-list{
        justify-content: start;
    }
    .back-mobile{
        display: flex !important;
        /* display: block !important; */
    }
    .list-menu{
        padding: 0 20px;
        justify-content: normal;    
    }
    .offer-card-heading-text{
        font-size: 14px;
        font-weight: 600;
    }
    .offer-card-heading-text span,
    .offer-card-heading-text strong{
        font-weight: 600;
        padding: 5px 10px;
    }
    .modal-single-item{
        font-size: 11px;
    }
    .modal-single-item.topping-items,
    .modal-single-item span{
        font-size: 12px;
    }
    .required-label{
        font-size: 14px;
    }
    .accordion-item:first-of-type .accordion-button,
    .voucher-title button{
        font-size: 15px;
        font-weight: 600;
    }
    .extra-topping input[type="checkbox"]:checked + label,
    .extra-topping input[type="checkbox"]:not(:checked) + label,
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label{
        padding-left: 30px !important;
    }
    .size-content [type="radio"] + label{
        padding: 10px 8px !important;
    }
    .radio-btn-style{
        padding: 12px 16px;
    }
    .product-modal-more-optional{
        font-size: 13px;
    }
    .tailstyle-content label,
    .tailstyle-content [type="radio"]:checked + label,
    .tailstyle-content [type="radio"]:not(:checked) + label,
    .tailstyle-content .form-check-label{
        padding: 0 45px 0 0 !important;
        min-height: 120px !important;
    }
    .tailstyle-content .image-radio-box img,
    .tailstyle-content .image-radio-box img.image-radio{
        height: 120px !important;
        margin-left: -20px !important;
    }
    .tailstyle-content .modal-single-item{
        padding-left: 45% !important;
        padding-right: 37px !important;
    }
    .tailstyle-content .modal-single-item::after {
        background: linear-gradient(90deg, rgba(0, 244, 254, 0) 40%, #92918f 45%) !important;
    }
    .tailstyle-content [type="radio"]:checked + label .modal-single-item::after {
        background: linear-gradient(90deg, rgba(254, 0, 0, 0) 40%, #e41837 45%) !important;
    }
    .image-radio-box{
        width: 60px;
        height: 80px;
    }
}
@media (max-width: 480px){
    .image-radio-box{
        width: 60px;
        height: 80px;
    }
    .offer-card-heading-text{
        align-items: inherit;
    }
    .offer-card-heading-box{
        flex-wrap: wrap;
    }
    .container-menuitems{
        padding: 0 !important;
    }
    .banner-slider{
        height: 120px;
        object-fit: inherit;
    }
    .bucket-cart-text{
        font-size: 18px;
    }
    .add-sugestive-card.card{
        min-width: 200px;
        width: 200px;
    }
    .offer-heading{
        font-size: 24px;
        padding-top: 20px !important;
    }
    .add-bottom-price{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .socilmedia-icon a{
        width: 35px;
        height: 35px;
        font-size: 22px;
    }
    .tailstyle-content .image-radio-box img, .tailstyle-content .image-radio-box img.image-radio{
        margin-left: -20px;
    }
    .tailstyle-content .modal-single-item {
        padding-left: 45% !important;
        padding-right: 35px !important;
    }
    .tailstyle-content .modal-single-item::after {
        background: linear-gradient(90deg, rgba(0, 244, 254, 0) 38%, #92918f 45%) !important;
    }
    .tailstyle-content [type="radio"]:checked + label .modal-single-item::after {
        background: linear-gradient(90deg, rgba(254, 0, 0, 0) 38%, #e41837 45%) !important;
    }
}


/* ==Mobile Navbar== */
.burgur-icon{
    font-size: 25px;
    color: black;
}
.contect-us {
    border: 2px solid;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.contect-us i{
    font-size: 20px;
}

.mobile-header {
    display: flex;
    padding: 6px 6px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #fff;
    z-index: 9999;
}

.side-menu {
    /* height: 100vh; */
    left: 0;
    bottom: 0;
    gap: 16px;
    width: 100%;
    display: flex;
    padding: 15px;
    position: fixed;
    z-index: 9999999;
    background: #fff;
    align-items: center;
    transition: all 0.4s;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px
}

.side-menu .nav-item{
    display: flex;
    position: relative;
    align-items: center;
    margin: 0 !important;
    flex-direction: column;
    justify-content: center;
    font-size: 14px !important;
    transition: all 0.4s ease-in-out;
    text-transform: capitalize !important;
    &:hover{
        transform: scale(1.1);
        color: #006991 !important;
        .mobile-cart-quantity-badge {
            top: -2px !important;
            right: 5px !important;
            transition: all 0.4s ease-in-out;
        }
    }
}

.side-menu .nav-item i{
    font-size: 26px;
    line-height: 26px;
}

/* .side-menu-open{
    width: 280px;
    background: #fff;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 25px;
    transform: translateX(0%);
    transition: all 0.4s;
    padding-inline: 20px;
    z-index: 10000000;
} */

/* .back-drop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 9999999;
}
.back-drop-open{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    z-index: 10000000;
} */

.crose-icon {
    position: absolute;
    right: 20px;
    font-size: 30px;
    top: 10px;
}
.main-home{
    font-size: 20px !important;
    border-bottom: 1px dotted;
}
.mobile-nav{
    display: none;
}
.header-dropdown.dropdown-toggle{
    gap: 10px;
    display: flex;
    align-items: center;
}

.mobile-nav .header-dropdown.dropdown-toggle{
    gap: 0;
    padding: 0;
    font-size: 12px;
    flex-direction: column;
    font-weight: 800 !important;
}
.header-dropdown.dropdown-toggle::after{
    margin-left: 5px !important;
}
.mobile-nav .header-dropdown i {
    font-size: 26px;
    line-height: 26px;
}

.mobile-nav .header-dropdown span{
    display: block;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.mobile-nav .header-dropdown.dropdown-toggle::after{
    display: none;
}



@media (max-width: 991px){
    .explore-title{
        font-size: 26px;
    }
    .start-order-module-container{
        top: 50px;
       
    }
    .menue-container{
        top: 109px;
    }
    .order-card-sticky{
        display: none;
    }
    .suggest-items-mian{
        display: none;
    }
    .suggest-items-mian-p{
        display: none;
    }
    .suggestive-mobile{
        display: block;
        width: 340px;
        margin: 0 auto;
        margin-top: 65px;
    }
    .menu-order{
        display: block;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .side-menu .cart-mobile-view {
        display: block;
        position: static;
        background: transparent;
        box-shadow: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: auto;
        height: auto;
    }
    .voucher-custom-container{
        width: auto;
        margin-left: 0;
    }
    .cart-container{
        display: block;
        width: 340px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .menu-list-item{
        top: 56px;
    }
    .active-list::before {
        top: 12px;
    }
    .active-list::after{
        top: 11px;
    }
    .container-menuitems{
        /* width: 80% !important; */
    }
    .account-form{
        max-width: 95%;
    }
    .my-detail-form{
        width: 100%;
    }
    .order-card-sticky-p{
        display: none;
    }
    .store-map-item-remove #simplex-maps-parent-container{
        height: 60vh;
    }
    .cart-box{
        display: none;
    }
    .account-box{
        padding: 0;
    }
    .account-box span{
        display: none;
    }
    .custom-input.datetime-input {
        position: relative;
        appearance: none !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
    }
    
    /* Placeholder ka effect create karna */
    .custom-input.datetime-input::before {
        top: 50%;
        left: 10px;
        color: #7c7c7c;
        position: absolute;
        pointer-events: none;
        content: "dd/mm/yyyy, --:--"; /* Custom Placeholder */
        transform: translateY(-50%);
    }
    
    /* Jab user kuch likhny lagy to placeholder remove ho */
    .custom-input.custom-input.datetime-input:focus::before,
    .custom-input.custom-input.datetime-input:valid::before {
        content: "";
    }
    
    /* Custom Calendar Icon */
    .custom-input.custom-input.datetime-input::after {
        top: 50%;
        right: 10px;
        color: #666;
        font-size: 20px;
        content: "\EB24";
        position: absolute;
        pointer-events: none;
        font-family: "remixicon";
        transform: translateY(-50%);
    }
}
/* ==scroll style== */
@media (max-width: 768px) {
    .mobile-nav{
        display: block;
    }
    .screen-header{
        display: none;
    }
    .main-footer{
        padding-bottom: 50px;
    }
    .footer-bottom{
        padding-top: 15px;
    }
    .Toastify__toast-container{
        bottom: 55px !important;
        width: 100% !important; 
        right: 0 !important;
    }
}

@media (min-width: 600px) {
    .emo-image {
        width: 4rem;
    }
    .emo-label {
        font-size: 18px;
    }
    .feedback-title{
        font-size: 1.1rem
    } 
}

@media (min-width: 768px) {
    .emo-image {
        width: 3rem;
    }
    .emo-label {
        font-size: 14px;
    }
    .feedback-title{
        font-size: 1rem
    } 
}

@media (min-width: 1024px) {
    .emo-image {
        width: 4rem;
    }
    .emo-label {
        font-size: 17px;
    }
    .feedback-title{
        font-size: 1.2rem
    } 
}

@media (min-width: 1250px) {
    .emo-image {
        width: 5rem;
    }
}

@media (min-width: 769px){
    .menue-items::-webkit-scrollbar {
        width: 5px !important;
      }
      
      /* Track */
      .menue-items::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px pink; 
        border-radius: 20px;
        height: 50px;
      }
       
      /* Handle */
      .menue-items::-webkit-scrollbar-thumb {
        background: #454545!important; 
        border-radius: 20px;
        height: 50px;
      }
    
      /* Handle on hover */
      .menue-items::-webkit-scrollbar-thumb:hover {
        background: #454545!important; 
      }
     
    
}
@media (max-width: 1500px){
    .order-card-sticky{
        height: 452px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .order-card-sticky::-webkit-scrollbar {
        display: none;
    }
    .order-card-sticky {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
   
    /* .product-order{
        padding-bottom: 220px;
    } */
}
@media (max-width: 610px){
    .featre-categories-container{
        justify-content: start;
    }
    .add-order-btn{
        width: 95%;
        padding: 15px 15px;
    }
}
@media (max-width: 375px){
    .add-order-btn{
        width: 98%;
    }
    /* .card-text {
        -webkit-line-clamp: 1 !important;
        min-height: 0px;
    } */
}
@media (max-width: 575px){
    .dropdown-pizza-size h6{
        font-size: 12px;
    }
    .dropdown-pizza-size ul{
        margin-inline: 0;
    }
    /* .dropdown-pizza-size .drop-down-box{
        flex-direction: column;
        text-align: center;
    } */
    .suggestive-items-card{
        margin-block: 2rem;
    }
    .my-cart-responsive {
        display: none;
    }
    .my-cart-responsive-mob {
        display: block;
        margin-bottom: 15px;
    }
    .cart-product-name{
        font-size: 15px !important;
    }
    .cart-product-price{
        font-size: 15px !important;
    }
    .account-container{
        display: none;
    }
    .mobile-account-link{
        display: block !important;
    }
    .my-detail-form{
        width: 85% !important;
        margin-top: 80px;
    }
    .delete-account{
       
        width: 100% !important;
    }
}
@media (max-width: 480px){
    .side-menu{
        gap: 8px;
        padding: 6px 10px;
    }
    .side-menu .nav-item{
        font-size: 12px !important;
    }
    .header-dropdown i,
    .side-menu .nav-item i{
        font-size: 22px;
        line-height: 25px;
    }
    .mobile-cart-quantity-badge {
        top: -2px;
        right: 3px;
    }
    .add-btn{
        font-size: 12px !important;
        padding: 4px 6px;
    }

}

@media (max-width: 420px){

    .google-button-style{
        width: 100%;
    }
    .kep-login-facebook {
        width: 100% !important;
    }
    .card-text {
        -webkit-line-clamp: 2;
    }
    /* .kep-login-facebook .fa {
        margin-right: 8vh !important;
    } */

    .product-modal-more-optional {
        display: flex;
        color: #000;
        flex-shrink: 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        text-align: right;
        font-style: normal;
        align-items: center;
    }
    .add-order-btn button{
        width: 80%;
        margin-left: 10px;
    }
    .add-order-btn .itemQuantity{
        font-size: 12px;
    }
    .add-order-btn .addOrderText{
        font-size: 12px;
    }
    .tailstyle-content .image-radio-box{
        padding-right: 40% !important;
    }
}

@media only screen and (min-width:1800px) and (max-width:5000px){


    .kep-login-facebook .fa {
        margin-right: 55px !important;
    }
}

@media (max-width: 1336px) {
    /* .sticky-btn {
        bottom: 60px;
    } */
    .suggest-items-mian{
        width: 30%;
    }
    .suggest-items-mian-p{
        width: 30%;
    }
}
/* @media only screen and (max-width: 991px) {
    .sticky-btn {
        position: fixed;
        top:auto;
        bottom: 0%;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
    }
    .cart-product-name{
        font-size: 18px;
    }
    .cart-product-price{
        font-size: 18px;
    }
}
@media only screen and (max-width: 768px) {
    .sticky-btn {
        position: fixed;
        top:auto;
        bottom: 0%;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
    }
} */
@media (max-width: 2560px){
    .order-card-sticky{
        width: 26%;
    }
    .suggest-items-mian{
        width: 24%;
        
    }

    .suggest-items-mian-p{
        width: 25%;
    }
    .order-card-sticky-p{
        padding-left: 0;
        width: 31%;
    }

    .suggest-items-mian{
        margin-left: 24px;
    }
}
@media (max-width: 1440px){
    .order-card-sticky{
        width: 30%;
    }
    .suggest-items-mian{
        width: 28%;
        right: 23px;
        margin-left: 0;
    }
    .suggest-items-mian-p{
        width: 30%;
        right: 0;
    }
    .menue-header{
        padding: 8px 20px;
        max-width: 1440px;
    }
  
}

@media (min-width: 426px){
    .menu-card {
        height: 20.625rem
    }
    .btn-item {
        top: 57%
    }
}

@media (min-width: 1200px){
    .menu-card {
        height: 22.5rem
    }
    .btn-item {
        top: 61%
    }
}
@media (min-width: 576px){
    .product-modal .modal-dialog{
        max-width: 550px !important;
    }
}
/* @media (max-width: 1440px){
    .order-card-sticky{
        width: 30%;
    }
}

} */