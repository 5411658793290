:root {
    --blue: rgb(0, 105, 145);
    --red: rgb(214, 0, 0)
}

a,
h1,
h2,
h3,
h4,
h5 {
    font-family: 'Open Sans', sans-serif;
}

.kep-login-facebook {
    font-family: Helvetica, sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: flex !important;
    font-size: calc(.27548vw + 12.71074px);
    text-decoration: none;
    text-transform: none !important;
    transition: background-color .3s, border-color .3s;
    background-color: rgb(0, 105, 145) !important;

    border: calc(0.06887vw + 0.67769px) solid rgb(23, 120, 242) !important;
    padding: 18px !important;
    text-align: center !important;
    border: none !important;
    border-radius: 5px !important;
    width: 355px !important;

}

.kep-login-facebook .fa {
    margin-right: 61px !important;
}

.btn-close {
    z-index: 1;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    border: 0;
    border-radius: 0.375rem;
    opacity: .5;
}

.helloGoogle {
    background-color: rgb(0, 105, 145) !important;
    display: flex !important;
    align-items: center;
    color: white !important;
    box-shadow: unset !important;
    padding: 0px !important;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 700 !important;
    font-family: Helvetica, sans-serif !important;
}

.no-account {
    margin-top: 15px;
    display: block;
    margin-left: 10px;
}

.google-button-style {
    text-align: center;
    background-color: rgb(0, 105, 145) !important;
    border: none;
    color: white !important;
    border-radius: 5px;
    width: 355px;
    font-weight: 700;
    padding: 10px;
}

.f-w-700 {
    font-weight: 700;
}

.f-w-400 {
    font-weight: 400;
}

.f-w-500 {
    font-weight: 500;
}

.blue-clr {
    color: var(--blue);
}

.font-small {
    font-size: 12px !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.payment-radio [type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    font-size: 16px !important;
}

.submit-btn {
    border: none;
    margin-top: 20px;
    background-color: var(--blue);
    color: white;
    padding: 10px 35px;
    border-radius: 10px;
    font-weight: 600;
}

.f-md {
    font-size: 20px !important;
}

.emo-image {
    width: 3rem;
}

.emo-label {
    font-size: 14px;
}

.feedback-title {
    font-size: 1rem
}

.main-header {
    top: 0;
    width: 100%;
    z-index: 999;
    position: fixed !important;
}

.main-header .dropdown-menu.show{
    right: 0;
    left: auto;
}

.map-wrapper {
    position: relative;
    width: 100%;
    height: 20rem;
}

.custom-container {
    width: 77%;
    margin: 0 auto;
}

.nav-item {
    align-items: center;
    padding-inline: 20px;
    color: #454545 !important;
    font-size: 16px !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    /* padding-bottom: 20px; */
    /* margin-top: 30px; */
}

.avatar-logo {
    width: 30px;
    height: 30px;
    margin: 0 10px 0;
}

.avatar-logo-2 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.btn-success {
    background-color: transparent !important;
    color: #454545 !important;
    border: none !important;
    font-weight: bold !important;

}

.d-names {
    z-index: 99999 !important;
}

.start-order-module-container {
    background-color: rgba(0, 0, 0, .5);
    padding: 15px;
    position: fixed;
    width: 100%;
    top: 68px;
    z-index: 99;
}

.start-order-module {
    text-align: center;
    position: relative;
}

.select-location {
    margin: unset !important;
    color: #454545 !important;
    font-size: 12px !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    /* padding-inline: 20px; */

}
.select-location b{
    width: 100%;
    max-width: 60px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
}

.delivering-to {
    font-size: 16px;
    font-family: "Helvetica Neue";
    font-weight: 400;
    margin: unset;
}

.brandLogo {
    margin-right: 56px !important;
}

.outer-delivery {
    display: flex;
    justify-content: space-around;
}

.delivery {

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1a1a1a;
    padding: 5px 33px;
    cursor: pointer;
}

.location-heading {
    font-size: 16px;
    font-style: normal;

    line-height: 50px;
    color: #1A1A1A;

    text-align: center;
    font-style: normal;
    font-weight: 700;
}

.hightLight {
    color: rgb(0, 122, 175);
    border-bottom: 3px solid var(--blue);
}

.start-order-module a {
    color: #fff;
    text-decoration: none;
    font-size: 40px;
    font-weight: 800;
    padding: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
}

.start-order-module a:hover {
    color: #fff;
}

.start-order-module a:first-child {
    background: var(--blue);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px 60px;
}

.start-order-module a:last-child {
    background: #e41837;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px 80px;
}

.delivery-item {
    width: 40px;
    margin-right: 15px;
}

.or-item {
    position: absolute;
    top: 12px;
    width: 45px;
    height: 45px;
    background-color: white;
    font-size: 20px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

/* .active{
    background: #454545 !important;
    color: #fff !important;
    border-radius: 10px 10px 0 0;
} */
/* ==hero banner== */
.hero-banner {
    background-color: #454545;
    width: 100%;
    padding-top: 180px;
    padding-bottom: 20px;
}

.banner-image {
    height: 710px;
}

.NaveLinksList {
    text-decoration: none !important;
    color: inherit;
}

.home-banner-slider {
    width: 100%;
    height: 100%;
    float: right;
    /* padding-left: 10px; */
}

.banner-spinner {
    width: 100px !important;
    display: flex !important;
    margin: 0 auto !important;
}

.bbbb {
    width: 100px !important;
    display: flex !important;
    margin: 0 auto !important;
}

.home-sec-banner {
    width: 101%;
}

.menu-popup-slider {
    width: 100%;
    /* height: 80vh; */
    /* padding-left: 10px; */
}

/* ===footer=== */
.main-footer {
    background-color: #fff;
}

.footer-container {
    display: flex;
    gap: 50px;
}

.footer-box {
    width: 210px;
}

.fotter-items {
    list-style: none;
    padding: 10px 0;
    color: #454545;
}

.image-location-outer {
    position: relative;
    top: 41px;
    right: 46px;
}

.fotter-items li:first-child {
    font-weight: 800;
    color: #454545;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.fotter-items li {
    padding: 1px 0;
    font-size: 15px;
    font-weight: 600;
}

.fotter-items li a {
    text-decoration: none;
    color: #454545;
}

.fotter-items li a:hover {
    color: var(--blue);
}

.social-media-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.socilmedia-icon {
    display: flex;
    gap: 15px;
}

.socilmedia-icon a {
    font-size: 30px;
    background: #333;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s;
}


.socilmedia-icon a:hover .ri-facebook-fill {
    color: #3b5998;
    transition: all 0.3s;
}

.socilmedia-icon a:hover .ri-youtube-fill {
    color: #c4302b;
    transition: all 0.3s;
}

.socilmedia-icon a:hover .ri-twitter-fill {
    color: #00acee;
    transition: all 0.3s;
}

.socilmedia-icon a:hover .ri-instagram-line {
    color: rgb(228, 64, 95);
    transition: all 0.3s;
}

.app-stor {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}

.stors-link {
    width: 140px;
    height: 45px;
    cursor: pointer;
}

.menu-list-item {
    width: 100%;
    background-color: #fff;
    z-index: 99;
}

.list-menu {
    display: flex;
    justify-content: center;
    gap: 10px;
    overflow-x: auto;
    overflow-y: hidden;
}

.list-menu::-webkit-scrollbar {
    display: none;
}

.list-menu a {
    color: #000;
    text-decoration: none;
    margin-top: 10px;
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 15px;
    margin-inline: 12px;
    white-space: nowrap;

}

.list-menu .active-list {
    position: relative;
    background-color: #4a4a4a;
    padding: 5px 15px 0px;
    color: white !important;
    border-radius: 10px 10px 0 0;
}

.order-active {
    position: absolute;
    width: 106px;
    height: 30px;
    background-color: #454545 !important;
    top: 28px;
    left: -15px;
}

.active-list::before {
    content: "";
    position: absolute;
    background-image: url(../assetes/images/object2-menu.png);
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    right: -38px;
}

.active-list::after {
    content: "";
    position: absolute;
    background-image: url(../assetes/images/object-menu.png);
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    left: -34px;

}

.cart-link {
    text-decoration: none;
}

.sticky-btn {
    position: sticky;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    width: 100%;
    padding: 15px 5px
}

.sticky-btn a {
    background-color: var(--blue);
}

.sticky-btn a:hover {
    color: #fff;
}

/* ===login form=== */
.login-form {
    max-width: 376px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0.5px 10px 0px;
    border-radius: 15px;
    background-color: white;
    margin: 0 auto;
    padding: 10px;
    margin-top: 50px;
}

.r-pad {
    padding-inline: 0px !important;
}

.form-container {
    background-color: rgba(0, 0, 0, 0.06);
    padding-block: 50px;
}

.login-data {
    text-transform: uppercase;
    font-weight: bold;
    color: #4a4a4a;
    letter-spacing: 0.25px;
    font-size: 15px;
}

.forgot-password {
    font-size: 10pt;
    text-decoration: none;
    padding-block: 10px;
    cursor: pointer;
}

.about-privice {
    font-size: 12px;
    font-weight: bold;
    color: #4a4a4a;
    margin-top: 10px;
}

.custom-input {
    width: 100%;
    border: 0 !important;
    padding: 10px !important;
    color: #7c7c7c !important;
    border-radius: 5px 5px 0 0 !important;
    border-bottom: 3px solid #7c7c7c !important;
    background-color: rgba(0, 0, 0, 0.06) !important;
}
.form-control:focus {
    color: #212529 !important;
    background-color: rgba(0, 0, 0, 0.06) !important;
    border-color: #2979ff !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.form-control.custom-input::placeholder,
.custom-input::placeholder{
    color: #7c7c7c !important;
}

.login-btn {
    width: 100%;
    border: 0;
    margin-top: 15px;
    padding: 15px;
    font-weight: 600;
    border-radius: 5px;
    color: #fff;
    transition: all 0.3s;
    text-decoration: none;
    outline: none;
    background-color: var(--blue);
    cursor: pointer;
}

.create-prof-btn {
    width: 100%;
    border: 0;
    margin-top: 15px;
    padding: 15px;
    font-weight: 600;
    border-radius: 5px;
    color: #fff;
    transition: all 0.3s;
    text-decoration: none;
    outline: none;
    background-color: var(--blue);
    cursor: pointer;
}

.create-prof-btn-active {
    background-color: var(--blue);
    cursor: pointer;
}

.create-prof-btn-inactive {
    background-color: #7c7c7c;
    cursor: default;
}

.confirm-location-btn {
    width: 100%;
    border: 0;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
    color: #fff;
    background-color: var(--blue);
    color: #fff;
    transition: all 0.3s;
    text-decoration: none;
    cursor: pointer;
}

.login-btn2 {
    width: 100%;
    border: 0;
    margin-top: 15px;
    padding: 15px;
    font-weight: 600;
    border-radius: 5px;
    color: #fff;
    background-color: rgb(0 102 145 / 59%) !important;
    color: #fff;
    transition: all 0.3s;
    text-decoration: none;
    cursor: not-allowed !important;
}

.confiramtion-btns {
    width: 130px;
    border: 0;
    margin-top: 15px;
    padding: 15px;
    font-weight: 600;
    border-radius: 5px;

    color: #fff;
    transition: all 0.3s;
    text-decoration: none;
    cursor: pointer;
}

.start-btn {
    position: sticky;
    bottom: 55px;
    /* z-index: 9; */
}

.login-btn:hover {
    background-color: var(--blue);
    color: #fff;
}

.account-form {
    max-width: 80%;
    box-shadow: rgb(0 0 0 / 15%) 0px 0.5px 10px 0px;
    border-radius: 15px;
    background-color: white;
    margin: 0 auto;
    padding: 10px;
    margin-top: 50px;
}

/* ===Contect Form=== */
.contact-form {
    max-width: 50%;
    box-shadow: rgb(0 0 0 / 15%) 0px 0.5px 10px 0px;
    border-radius: 10px;
    background-color: white;
    margin: 0 auto;
    padding: 10px;
    margin-top: 50px;
}

.select-store {
    width: 100%;
    background-color: #ededed !important;
    color: #000000 !important;
    padding: 11px 5px;
    margin-top: 15px;
    border: 0;
    border-bottom: 3px solid #7c7c7c;
    border-radius: 5px 5px 0 0;
    color: #7c7c7c !important;
}

/* ===forgot password item=== */
.forgot-password-items {
    margin-top: 100px;
}
.verify-otp-header {
    transform: translate(0%, 10%);
}

.about-password {
    font-size: 14px;

}
#OTPInput:first-child div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
}

.timer-text {
    color: var(--blue) !important
}

.mendatory-star {
    color: var(--red)
}

#OTPInput input{
    width: 100% !important;
    /* height: 80px !important; */
    padding: 10px !important;
    /* border: none; */
    /* border-bottom: 1px solid var(--blue); */
    text-align: center;
    font-size: 25px;
    /* background-color: whitesmoke; */
    border: 1px solid var(--red);
    color: var(--blue);
    font-weight: 600;
}

#OTPInput input:focus{
    outline: none;
    /* border-bottom: 1px solid var(--red); */
}

.forgot-password-form {
    padding: 10px 46px;
}

/* ===Alliance=== */
.alliance-header {
    background-color: #454545;
    text-align: center;
    overflow: auto;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    padding: 12px;
    width: 100%;
}

.alliance-container {
    /* background-color: #f7fcff; */
    padding-bottom: 50px;
    margin-top: 75px;
}

/* =========== Animation======= */

/* .bank-content {
    --border-size: 3px;
    --border-angle: 0turn;
   
    background-image: conic-gradient(from var(--border-angle), #fff, #f9f9f9 50%, #ffffff), conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
    background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-animation: bg-spin 3s linear infinite;
            animation: bg-spin 3s linear infinite;
  }
  @-webkit-keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  .bank-content:hover {
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  
  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  } */

/* ==== Animation off ===== */
.visa-img {
    border-left: 10px solid black;
    border-top: 10px solid #464646;
    border-right: 10px solid black;
    border-bottom: 10px solid #343333;
}

.hbl-img {
    border-left: 10px dashed #018178;
    border-radius: 10px solid;
    border-right: 10px solid #04afa3;
    border-bottom: 10px solid #00867d;
    border-top: 10px solid #017069;
}

.silkbank-img {
    border-top: 10px solid rgb(118 3 46);
    border-bottom: 10px solid rgb(122 1 47);
    border-left: 10px dashed rgba(141, 0, 53, 255);
    border-right: 10px solid rgb(173 7 69);
}

.bogobank-img {
    border-top: 10px solid rgb(239 140 58);
    border-bottom: 10px solid rgb(239 140 58);
    border-left: 10px dashed rgb(219 113 25);
    border-right: 10px solid rgb(213 128 58);
}

.faysalbank-img {
    border-bottom: 10px solid rgba(35, 146, 148, 255);
    border-left: 10px dashed #266a85;
    border-right: 10px solid #266a85;
    border-top: 10px solid rgba(35, 146, 148, 255);
}

.voucherbank-img {
    border-top: 10px solid rgb(189 22 34);
    border-bottom: 10px solid rgb(189 22 34);
    border-right: 10px solid rgb(169 17 28);
    border-left: 10px dashed rgb(169 17 28);
}

.alince-image {
    height: 180px;
}

.bank-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.bank-heading {
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
    margin-top: 10px;
    margin-left: 25px;
    color: #726f6f;
    margin: 0;
    padding: 8px 21px;
}

.bank-card {
    border-radius: 3px;
    background: #fff;
    box-shadow: 2px 2px 10px #0003;
    margin-top: 20px;
    width: 100%;
    margin: 0 auto !important;
    margin-top: 15px !important;
}

.bank-card ul {
    padding: 0;
    margin-left: 25px;
}

.bank-card ul li {
    font-size: 14px;
}

.card-dropdown {
    width: 100%;
}

.manu-card-option {
    width: 100%;
    border: solid 1px #cccccc !important;
    background-color: #ededed !important;
    color: #000000 !important;
    font-weight: 500;
    padding: 5px 5px;
    border-radius: 5px;
}

.dropdown-toggle::after {
    color: gray;
    margin-left: 15px !important;
}

.iten-select-dropedown {
    width: 35%;
    margin-top: 10px;
}

.add-quantity {
    width: 60% !important;
}

.voucher-body {
    border-top: 1px solid #c8c7cc;
}

.voucher-code {
    width: 100%;
    padding: 5px 10px;
    border: 1px solid rgb(151, 151, 151);
    border-radius: 15px;
    font-size: 18px;
}

.voucher-title button {
    font-size: 22px;
    color: #000 !important;
    font-weight: 700;
}

.accordion-button:not(.collapsed) {
    color: black !important;
    background-color: white !important;
    box-shadow: none !important;
}

.apply-code-btn {
    border-radius: 10px;
    background-color: rgb(0, 122, 175);
    cursor: pointer;
    padding: 7px 45px;
    border: 0;
    color: white;
    font-weight: 700;
    margin-left: 10px;
}

.card-details-btn {
    border-radius: 10px;
    background-color: rgb(0, 122, 175);
    cursor: pointer;
    padding: 7px 25px;
    border: 0;
    color: white;
    font-weight: 700;
    margin-left: 10px;
}

.voucher-item {
    width: 65%;
}

.voucher-custom-container {
    width: 100%;
    margin-left: 20px;
}

.voucher-accordian {
    border-radius: 20px !important;
}

.code-container {
    box-shadow: rgb(0 0 0 / 15%) 0 0.5px 10px 0px;
    border-radius: 20px !important;
}

.code-container-item {
    box-shadow: unset !important;
    border-radius: unset !important;
}

.code-container-for-kitchen {
    box-shadow: rgb(0 0 0 / 15%) 0 0.5px 10px 0px;
    border-radius: 20px !important;
}

.option-title .accordion-button {
    padding: 24px 0;
    border: 0 !important;
}

.accordion-item:first-of-type .accordion-button {
    border-radius: 20px !important;
    font-size: 18px;
}

.voucher-body i {
    font-size: 26px;
    margin-left: 20px;
    color: #454545;
}

.about-order {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: rgb(239, 239, 244);
}

.add-items-order {
    padding-block: 10px;
    text-align: center;
    border-top: 1px solid #c8c7cc;
    border-bottom: 1px solid #c8c7cc;
    font-size: 13px;
}

.order-card-stk {
    position: fixed;
    top: 300px;
    /* width: 30%; */
    padding-right: 10px;
    padding-bottom: 10px;
    height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
}

.order-card-sticky {
    position: fixed;
    /* top: 315px;   with cross sell items */
    top: 165px;
    /* width: 30%; */
    padding-right: 10px;
    padding-bottom: 10px;
    height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
}

.order-card-sticky-p {
    position: absolute;
    bottom: 0;
    /* width: 30%; */
    padding-right: 10px;
    padding-bottom: 10px;
    /* height: 550px; */
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    padding-bottom: 0
}

.order-card-sticky::-webkit-scrollbar {
    display: none;
}

.order-card-sticky {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.cart-values-left {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000;
}

.cart-values-right {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    margin-top: 2px;
    color: #000;
}

.cart-order-list {
    margin-bottom: 1rem;
    max-height: 195px;
    overflow: auto;
}

.cart-product-name {
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #ea002a;
    font-family: inherit;
    font-size: 20px;
    letter-spacing: .5px;
    margin-bottom: 0;
}

.cart-product-price {
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #4d4d4d;
    font-size: 20px;
    letter-spacing: .5px;
    margin-bottom: 0;
}

.cart-buttons-control {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cart-outer-quantity .quantity-btn {
    background-color: var(--blue);
    font-size: 22px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    /* font-weight: 800; */
    border-radius: 50%;
    border: none;
    /* padding-bottom: 4px; */
    line-height: 0;
}

.quantity-minus {
    background-color: var(--red) !important;
}

.quantity-disable {
    background-color: lightgray !important;
}

.cart-extra {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    margin: auto;
}

.cart-extra:hover {
    color: #666666;
}

.cart-single-item-right {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    margin-top: 2px;
    color: #666666;
    margin-bottom: 5px;
}

.cart-discount-left {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: left;
    width: 70%;
    margin-top: 2px;
    color: #666666;
    margin-bottom: 5px;
}

.cart-discount-right {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    margin-top: 2px;
    color: #666666;
    margin-bottom: 5px;
}

.pick-up-item {
    font-size: 12px;
}

.special-ins {
    background-color: #eeeeee !important;
}

/* ==Tesrm and comdition== */
.terms-conditions .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.terms-conditions .accordion-button:focus {
    box-shadow: none;
}

.terms-conditions .accordion-item {
    background-color: #323232 !important;
    color: #fff !important;
    border: none;
    padding: 0;
}

.terms-conditions .accordion-body {
    padding: 0;
}

.terms-conditions .accordion-item button {
    background-color: #323232 !important;
    color: #fff !important;
    padding: 0;
}

.terms-conditions {
    background-color: #323232;
    color: #fff;
    padding: 60px 0px;
}

.term-headind {
    font-size: 24px;
    text-transform: uppercase !important;
}

/* ==menue== */
.menu-order {
    border-radius: 8px;
    height: 54px;
    background-color: var(--blue);
    width: 90%;
    cursor: pointer;
    padding-inline: 16px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    display: none;
}

/* .menu-order:hover {
    color: #ea002a !important
} */

/* .cart-container{
    display: none;
} */
.menue-container {
    background-color: #4a4a4a;
    width: 100%;
    z-index: 99;

}

.menue-header {
    display: block;
    width: 100%;
    display: block;
    overflow-x: auto;
    text-align: center;
    padding-block: 8px;
    text-align: center;
}

.menue-header::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

.menue-header::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.menue-header::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
}

.menue-header a {
    color: #fff;
    margin: 0 8px;
    font-size: 12px;
    font-weight: bold;
    padding-inline: 5px;
    white-space: nowrap;
    transition: all 0.3s;
    text-decoration: none;
    text-transform: uppercase;
    /* border-bottom: 4px solid transparent; */
}

/* .menue-header a:hover {
    border-bottom: 4px solid #fff;
    color: #fff;
} */

.menue-header a.active-tab {
    background: var(--red);
    padding: 5px 15px;
    border-radius: 5px;
}

.menue-items-container {
    margin-top: 15px;
}

.menue-card{
    display: block;
    position: relative;
}
.menue-card a{
    display: block;
}
.menu-img {
    height: 200px;
    object-fit: cover;
    position: relative;
    border-radius: 10px;
}
.bounce-2 {
    background-color: var(--blue);
    box-shadow: 1px 1px 5px #00000054;
    background-color: var(--blue) !important;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    top: -7px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -7px;
    color: #fff;
    flex-direction: column-reverse;
    line-height: 13px;
}

.bounce-3 {
    /* background-image: linear-gradient( 109.6deg, rgba(255,180,24,1) 11.2%, rgba(247,49,49,1) 91.1% ); */
    box-shadow: 1px 1px 5px #00000054;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--blue) !important;
    top: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -10px;
    color: #fff;
    flex-direction: column-reverse;
    line-height: 13px;
}

.discount-percentage-tag {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

.discount-percentage-tag {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

.save-discoun {
    font-size: 12px;
}

.menue-navbar .mobile-header {
    position: inherit !important;
}

/* ==Animation== */
@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

.your-cart {
    background-color: #fff;
    border-radius: 5px;
    padding: 15px;
}

.cart-mobile-view {
    display: none;
    text-decoration: none;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 10;
    padding-inline: 7px;
    background: #ffffffe6;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.cart-mobile-view .nav-item{
    padding: 0;
    text-decoration: none;
}


.your-text {
    border-bottom: 1px solid var(--blue);
    color: var(--blue);
    font-family: inherit;
    font-weight: 600;
    padding: 0 0 8px;
    gap: 10px;
}

.back-btn {
    align-items: center;
    background-color: #fff;
    border: 2px solid var(--blue);
    border-radius: 50%;
    color: var(--blue);
    display: flex;
    font-size: 20px;
    font-weight: 800;
    height: 30px;
    justify-content: center;
    transition: all .3s;
    width: 30px;
    text-decoration: none;
}

.back-btn:hover {
    background-color: var(--blue);
    color: #fff;
}

.your-items {
    padding-top: 15px;
}

.kfc-bucket {
    align-items: center;
    aspect-ratio: 566/350;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 34.5333px 103.6px -8.63333px rgba(0, 0, 0, .06);
    display: flex;
    flex-basis: 47.8%;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 37rem;
    overflow: hidden;
    padding-bottom: 3rem;
    padding-top: 1rem;
    padding-inline: 10px;
    text-align: center;
}

.bucket-cart-img {
    margin-right: 20px;
    width: 250px;
}

.bucket-cart-text {
    color: #fff;
    color: var(--white);
    font-size: 22px;
    margin-top: 15px;
}

.bucket-cart-btn {
    background-color: #ea002a;
    background-color: var(--blue);
    border: none;
    border-radius: 6px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
    padding: 10px 38px;
    text-transform: uppercase;
}

.cart-product-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.cart-items {
    /* background-color: #000; */
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0 34.5333px 103.6px -8.63333px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e5e5;
}

.item-container {
    align-items: start;
    box-shadow: 0 2px 8px 0 rgba(99, 99, 99, .2);
    display: flex;
    gap: 15px;
    padding: 16px;
}

.details-but-mob {
    background-color: #fff;
    border: none;
    color: #000;
    font-size: 10px;
    font-weight: 600;
    padding: 0px;
}

.mob-arrow {
    font-size: 19px !important;
    margin: 0px !important;
}

.item-container-mob {
    padding: 15px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart-item-name-price {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.card-header {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 15px !important;
    margin-inline: 10px;
    font-weight: 500;
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    max-height: 20px;
}

.card-price {
    font-size: 14px;
    padding-left: 10px;
    font-weight: 600;
    cursor: pointer;
}

.card-price span{
    padding: 0 !important;
    padding-left: 4px !important;
}

.add-bottom-price {
    /* position: absolute; */
    /* bottom: 40px; */
    left: 0px;
    margin-bottom: 0;
}

.card-text {
    color: #898989;
    margin: 5px 0 10px;
    min-height: 40px;
    padding-inline: 10px;
    font-size: 12px;
    max-height: 58px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    cursor: pointer;
}

.price-order {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-inline: 10px;
    padding-bottom: 15px;
    /* position: absolute; */
    bottom: 0;
    width: 100%;
}

.price-order span p {
    color: red;
    margin-left: 10px;
    text-decoration: line-through;
}

.price-order button {
    border-radius: 5px;
    padding: 7px 28px;
    color: #fff;
    background: var(--blue);
    font-weight: 500;
    border: none;
    width: 100%;
}

.menu-card {
    box-shadow: 1px 1px 5px #00000054;
    border-radius: 15px;
    background-color: #f8f9fa;
    height: 18.75rem;

    position: relative;
}

.validate-cart {
    height: 250px !important
}

.fav-card {
    box-shadow: 1px 1px 5px #00000054;
    border-radius: 15px;
    background-color: #f8f9fa;
    height: 270px;
    position: relative;
    cursor: pointer;
}

.add-btn {
    width: 94%;
    text-align: center;
    position: absolute;
    bottom: 5px;
    border: none;
    background: var(--blue);
    border-radius: 5px;
    font-weight: 700;
    color: #fff;
    left: 50%;
    transform: translate(-50%);
}

.fav-hart {
    color: #e41837;
    font-size: 22px;
    padding-right: 10px;
}

.del-btn {
    float: right;
    border: none;
    background: no-repeat;
    border-bottom: 1px solid;
}

.offer-menu {
    box-shadow: 1px 1px 5px #00000054;
    border-radius: 15px;
    background-color: #f8f9fa;
    height: 285px;
    position: relative;
}

.del-account {
    border: none;
    color: var(--red);
    border-radius: 0px;
    background: transparent;
}

.offer-heading {
    font-size: 26px;
    font-weight: 700;
    border-bottom: 2px solid;
    padding-bottom: 10px;
    padding-top: 50px;
}

/* .mt-90 {
    margin-top: 90px !important;
} */

.modal-content .modal-header {
    border-bottom: 0 !important;
}

.menue-items {
    display: flex;
    position: fixed;
    z-index: 999999;
    top: 129px;
    background-color: var(--blue);
    width: 100%;
    overflow: auto;
    gap: 30px;
    padding-inline: 15px;
}

/* .active-item{
    background: var(--blue);
    border-radius: 25px;
    padding: 15px 23px;
    box-shadow: 1px 1px 5px #00000054;
} */
.menue-items a {
    color: white;
    white-space: nowrap;
    text-decoration: none;
    padding: 15px 0;
    transition: all 0.3s;
}

.menue-items a:hover {
    background: white;
    border-radius: 25px;
    padding: 15px 23px;
    box-shadow: 1px 1px 5px #00000054;
    color: var(--blue);
}

.menue-catagories {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* ===delevery Form=== */
.dilivery-number {
    margin-top: 150px;
}

.dilivery-location h3 {
    font-size: 20px;
    font-weight: bold;
}

.dilivery-location .add-location {
    margin-top: 20px !important;
}

.modala {
    margin-top: 50px;
    z-index: 9999999 !important;
}

/* .select-location .css-tlfecz-indicatorContainer {
    display: none;
  } */
.form-delevery {
    background-color: rgba(0, 0, 0, 0.06);
    padding-block: 10px;
}

.delivery-location-icon {
    font-size: 20px;
    color: hsl(0deg 0% 68%);
    border-radius: 5px;
    padding: 1px 5px;
    margin-top: 5px;
    margin-left: 5px;
    background: white;
}

.my-cart-responsive {
    display: block;
}

.my-cart-responsive-mob {
    display: none;
}

.bucket-container {
    margin-top: 75px;
}

.d-fieldset {
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 2px;
    border-style: groove;
    border-color: rgb(192, 192, 192);
    border-image: initial;
    border-radius: 5px;
}

.d-legend {
    float: unset;
    width: auto;
    font-size: 16px;
    margin-bottom: 0;
}

.select-location .css-1s2u09g-control {
    border: none !important;
}

.select-location:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

/* ===About Us=== */
.aboutUs-container {
    margin-top: 75px;
    /* background: #fbfbfb; */
    padding-block: 30px;

}

.aboutUs-container h2 {
    font-size: 30px;
    font-weight: 700;
    font-family: 'Oswald', sans-serif;

}

.about-domino {
    margin-top: 50px;
}

.about-domino h4 {
    font-weight: 700;
    font-family: 'Oswald', sans-serif;
    font-size: 28px;
}

.about-domino p {
    font-weight: 400;
    font-size: 17px;

}

.about-domino ul li {
    font-weight: 500;
}

/* privay-policy */
.privacy-container {
    margin-top: 75px;
    /* background: #fbfbfb; */
    padding-block: 30px;
}

.privacy-container h2 {
    font-size: 30px;
    font-weight: 700;
    font-family: 'Oswald', sans-serif;
}

.privacy-policy {
    margin-top: 50px;
}

.privacy-policy h5 {
    font-weight: 700;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
}

.privacy-policy p {
    font-weight: 400;
    font-size: 17px;

}

.privacy-policy ul li {
    font-weight: 500;
}

/* terms& conditions */
.terms-container {
    margin-top: 75px;
    /* background: #fbfbfb; */
    padding-block: 30px;
}

.terms-container h2 {
    font-size: 30px;
    font-weight: 700;
    font-family: 'Oswald', sans-serif;
}

.terms {
    margin-top: 50px;
}

.terms h5 {
    font-weight: 700;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
}

.terms p {
    font-weight: 400;
    font-size: 17px;

}

.terms ul li {
    font-weight: 500;
}

.banner-slider {
    width: 100%;
    /* height: 180px; */
    object-fit: cover;
    margin: 0 auto;
    border-radius: 10px;
    cursor: pointer;
}

.container-banner-slider {
    margin-bottom: 0px;
    margin-top: 160px;
}

.border-bottom {
    border-bottom: 3px solid rgba(200, 199, 204, 0.5);
    padding-bottom: 15px;
}

.detail-order-cart {
    width: 90%;
    margin-top: 50px;
}

.detail-order-cart .cart-container {
    margin-top: 20px;
}

/* ===product card=== */
.produtc-photo {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 11px;
}

.box-shadow {
    box-shadow: rgb(0 0 0 / 15%) 0 0.5px 10px 0px !important;
}

.about-ordre {
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
    position: relative;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 15%)  0.5px 5px 1px;
}

.product-order {
    width: 100%;
    margin-top: 20px;
    position: relative;
    padding-inline: 20px;
    padding-bottom: 100px;
}

.offer-order {
    position: absolute;
    width: 100%;
    top: 300px;
    padding-bottom: 100px;
    padding-inline: 10px;
}

.about-ordre p {
    margin: 5px 0px;
}

.product-modal .modal-dialog {
    margin-top: 14px !important;
}

.modal-single-item {
    display: flex;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 20px;
    font-style: normal;
    align-items: center;
    justify-content: space-between;
}

.product-modal-more-optional {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #000;
}

/* .product-modal{
    margin-top: 150px;
} */
.product-modal .modal-content {
    height: 93vh;
    border-radius: 15px 15px 0px 0px;
    overflow-y: scroll;
    /* margin-top: 70px; */
}

.about-ordre a {
    font-size: 12px;
}

.crust-pizza {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 3px solid red;
}

.info-natural {
    border-radius: 8px;
    width: 100%;
    background-color: var(--blue);
    cursor: pointer;
    border: none;
    padding: 5px;
    color: #ffff;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    justify-content: center;
}

.info-natural:hover {
    color: #ffff;
}

.product-modal .modal-content::-webkit-scrollbar {
    width: 5px;
    background-color: #ffffff;
    border-radius: 10px !important;
}
   
.product-modal .modal-content::-webkit-scrollbar-track {
    border-radius: 10px !important;
    background-color: #dedede;
}
   
.product-modal .modal-content::-webkit-scrollbar-thumb {
    background-color: #212529;
    border-radius: 10px !important;
    outline: none
}

.add-order-btn .quantity-btn {
    background-color: var(--blue);
    font-size: 24px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
    border: none;
}

.add-order-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgb(0 0 0 / 15%) 0 0.5px 10px 0px;
    margin-top: 19px;
    padding: 20px 15px;
    border-radius: 20px;
    width: 580px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 999999;
}

.add-order-btn button {
    background-color: var(--blue);
    border: none;
    padding: 7px 15px;
    width: 60%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    color: #ffff;
    font-size: 16px;
    border-radius: 7px;
    font-weight: 600;

}

.required-label {
    border-width: 1px;
    --text-opacity: 1;
    font-weight: 700;
    font-size: 14px;
    color: rgb(33, 37, 41);
}

.modal-ku {
    width: 950px;
    margin: auto;
}

.modal-header {
    flex-direction: column-reverse;
    padding: 0 !important;
}

#otp-modal-header {
    display: flex;
    flex-direction: column;
}

.btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white !important;
    /* opacity: 1 !important; */
}

.modal {
    z-index: 9999 !important;
}

.search-field {
    padding-left: 34px;
    color: #666666;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 5px;
    height: 30px;
    width: 100%;
    outline-color: #666666;
}

.search-field:focus {
    border-color: #2979ff !important;
    outline: 0 !important;
    box-shadow: none !important;
}

/* .modal-backdrop{
    z-index: 999999999 !important;
} */

/* ===Account form=== */
.account-form .nav-link {
    color: #000;
    box-shadow: rgb(0 0 0 / 15%) 0px 0.5px 10px 0px;
    font-size: 18px;
    font-weight: 700;
    padding-block: 12px;
    border-radius: 15px !important;
    text-transform: capitalize !important;
}

.account-form .nav-item {
    padding-block: 7px !important;
    margin: 0 !important;

}

.account-form .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--blue) !important;
}

.my-detail-form {
    width: 80%;
    margin: 0 auto;
}

.delete-account {
    width: 70%;
}

.active-voucher {
    box-shadow: 0px 3px 9px -2px rgba(31, 195, 106, 0.75);
    -webkit-box-shadow: 0px 3px 9px -2px rgba(31, 195, 106, 0.75);
    -moz-box-shadow: 0px 3px 9px -2px rgba(31, 195, 106, 0.75);
    padding: 10px;
}

.expired-voucher {
    box-shadow: 0px 3px 9px -2px rgba(226, 24, 54, 0.75);
    -webkit-box-shadow: 0px 3px 9px -2px rgba(226, 24, 54, 0.75);
    -moz-box-shadow: 0px 3px 9px -2px rgba(226, 24, 54, 0.75);
    padding: 10px;
    margin-top: 20px;
}

.my-address .form-check-input:checked {
    background-color: rgb(214, 0, 0);
    border-color: white;
    width: 40px !important;
    padding-block: 10px;
}

.my-address .form-check-input:focus {
    box-shadow: none !important;
}

.my-address .form-switch .form-check-input {
    width: 40px !important;
    padding-block: 10px;
}

.my-address a {
    color: rgb(214, 0, 0);
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px;
}

.my-address {
    background-color: rgb(153 153 153 / 6%);
    padding: 12px;
    border-bottom: 3px solid var(--blue);
    margin-bottom: 10px;
}

.home-address i {
    color: var(--blue);
    font-size: 20px;
}

.login-email-g {
    height: 100vh;
}

.new-order {
    color: #666;
}

.order-card {
    padding: 10px 20px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    background-color: var(--blue);
    border-left: 10px solid#e41837;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
}

.order-card p {
    color: #fff;
    margin-bottom: 5px;
}

.click-errow {
    background-color: #e41837;
    color: #fff;
    font-size: 26px;
    font-weight: 900;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}

.order-card:hover {
    border-left: 15px solid#e41837;
}

.order-card:hover .click-errow {
    width: 50px;
    height: 50px;
    font-size: 30px;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.past-order {
    height: 400px;
    overflow-y: scroll;
    padding-bottom: 40px;
}

.past-order::-webkit-scrollbar {
    width: 5px;
}

.past-order::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 0px;
}

.past-order::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 0px;
    background: #a9a6a6;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: var(--blue) I !important;
}

.order-number {
    display: flex !important;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 15px;
}

.order-number h6 {
    color: #999;
}

.past-order-detail {
    text-align: center;
}

.past-order-detail img {
    width: 50%;
    border-radius: 10px;
}

.cash-bordr {
    border-bottom: 4px solid #999;
}

.past-order-detail .modal-body {
    height: 85vh;
    overflow-y: scroll;
}

.past-order-detail .modal-body::-webkit-scrollbar {
    width: 10px;
}

.past-order-detail .modal-body::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 0px;
}

.past-order-detail .modal-body::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 0px;
    background: #a9a6a6;
}

.extra-hiding {
    font-size: 18px;
}

.order-accordian .accordion-item {
    border: 2px solid white !important;
}

.order-accordian .accordion-button:focus {
    box-shadow: none !important;
}

.order-accordian .accordion-button {
    padding-inline: 0 !important;
}

.wish-list-items {
    height: 440px;
    overflow-y: scroll;
    padding-bottom: 40px;
    padding-inline: 15px;
}

.wish-list-items::-webkit-scrollbar {
    width: 10px;
}

.wish-list-items::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 0px;
}

.wish-list-items::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 0px;
    background: #a9a6a6;
}

.reason-title {
    font-size: 18px;
    font-weight: 500;
}

.profile-image-upload {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.profile-image-upload img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

/* ===Offers Bar=== */
.offer-header {
    background-color: #fff;
    text-align: center;
    overflow: auto;
    white-space: nowrap;
    /* position: fixed;
    top: 68px; */
    width: 100%;
    color: white;
    padding-block: 15px;
    font-weight: 700;
    z-index: 99;
    overflow: hidden;
}

.domino-offers {
    cursor: pointer;
    background-color: #4a4a4a;
    cursor: pointer;
    /* padding-block: 17px; */
    padding-inline: 15px;
    border-radius: 15px 15px 0px 0px;
    position: relative;
    padding-top: 15px;
    padding-bottom: 20px;
    letter-spacing: 0.5px;
}

/* .domi-items{
    background-image: url(../assetes/images/object2-menu.png);
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
}
.domi-items2{
    background-image: url(../assetes/images/object-menu.png);
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
} */


.domino-offers::after {
    content: "";
    position: absolute;
    background-image: url(../assetes/images/object2-menu.png);
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    bottom: 0;
    display: flex;
    right: -32px !important;
}

.domino-offers::before {
    content: "";
    position: absolute;
    background-image: url(../assetes/images/object-menu.png);
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    left: -30px;
    bottom: 0;
    display: -webkit-flex;
}

.offer-header span:hover {
    color: var(--blue);
}

.offers-items-container {
    background-color: #4a4a4a;
    /* position: fixed; */
    /* top: 120px; */
    width: 100%;
    text-align: center;
    z-index: 99;
    white-space: nowrap;
    overflow-x: auto;
    padding-block: 10px;
}

.offers-items-list {
    display: flex;
    align-items: center;
    justify-content: center;
}

.offers-items-list a {
    color: #fff;
    text-decoration: none;
    padding-inline: 15px;
    transition: all 0.3s;
    border-bottom: 3px solid transparent;
    font-size: 12px;
    font-weight: 700;

}

.offers-items-list a:hover {
    color: #fff;
    border-bottom: 3px solid #fff;
}

.offers-card {
    margin-top: 0px !important;
    margin-bottom: 90px;
    position: relative;
}

.offer-title {
    font-size: 18px;
    font-weight: 700;
    padding-inline: 14px;
    margin-top: 10px;
}

.offer-selection {
    margin-bottom: 6px;
    padding-inline: 15px;
    color: #999;
    font-weight: 500;
}

.mobile-account-link {
    margin-top: 100px;
    padding-inline: 35px;
    display: none;
}

.mobile-account-link a {
    color: #000;
    box-shadow: rgb(0 0 0 / 15%) 0px 0.5px 10px 0px;
    font-size: 14px;
    font-weight: 700;
    padding-block: 12px;
    border-radius: 15px !important;
    text-transform: capitalize !important;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    text-decoration: none;
    padding: 14px;
}

.mobile-account-link a:hover {
    background-color: var(--blue);
    color: #ffff;
}

/* abid css */
.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 100% !important;
    outline: none;
}

/* ===store-locator=== */

.store-locator {
    margin-top: 70px;
    background-color: rgb(175 175 175 / 6%);
    padding-block: 40px;
}

.order-card h3 {
    color: #fff;
    margin-bottom: 5px;
    font-size: 14px;
}

.order-card h5 {
    color: #fff;
}

.order-card h4 {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
}

.location-area {
    /* margin-top: 15px; */
    /* height: 65vh; */
    /* overflow-y: scroll; */
    padding: 10px 10px;
}

.location-area::-webkit-scrollbar {
    width: 10px;
}

.location-area::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 0px;
}

.location-area::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 0px;
    background: #a9a6a6;
}

.map-img {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    object-fit: cover;
}

/* ====Order Teacker==== */
.order-treck {
    margin-top: 70px;
    /* background-color: rgb(175 175 175 / 6%); */
    padding-block: 20px;
}

.corder-container {
    width: 65%;
    margin: 0 auto;
    padding: 15px;
}

.recived-order p {
    color: #999;
    margin-bottom: 5px;
    font-size: 14px;
}

.recived-order {
    box-shadow: 1px 1px 5px #00000054;
    border-radius: 5px;
    background-color: #fff;
    padding-block: 20px;
    text-align: center;
    padding-inline: 30px;
}

.order-details {
    box-shadow: 1px 1px 5px #00000054;
    border-radius: 5px;
    background-color: #fff;
    padding-top: 20px;
    padding-inline: 0px;
    margin-top: 10px;
}

.item-id-number {
    width: 265px;
}

.order-details-price {
    background-color: var(--blue);
    color: #fff !important;
}

.grande-total {
    background-color: #e41837;
    color: #fff !important;
    padding-block: 10px;
    margin-top: 10px;
    border-radius: 0 0 5px 5px;
}

.tracker-logo {
    width: 100%;
    margin-top: 15px;
}

/* ===Order Step=== */
.order-step {
    display: flex;
    justify-content: center;
    gap: 2px;
    margin-top: 10px;
    border-radius: 40px;
    border: 2px solid var(--blue);
    width: fit-content;
    margin: 0 auto;
    margin-top: 10px;
    padding: 2px;
    width: 99%;
}

.order-step div:first-child {
    border-radius: 40px 0 0 40px;
}

.order-step div:nth-child(5) {
    border-radius: 0 40px 40px 0;
}

.order-progressbar {
    background-color: var(--blue);
    padding: 15px 0;
    color: #fff;
    font-size: 19px;
    font-weight: 500;
    text-align: center;
    width: 20%;
}

.step-ready {
    background-color: #e41837;
}

.title-ordters {
    position: relative;
    display: flex;
    gap: 6px;
    text-align: center;
    width: 100%;
    justify-content: space-around;
}

.title-ordters span {
    color: var(--blue);
    font-size: 12px;
    font-weight: 500;
    border-top: 3px solid var(--blue);
    width: 18%;
    height: 45px;
    margin-top: 10px;
}

.step-title {
    color: #e41837 !important;
    border-top: 3px solid #e41837 !important;
}

/* ====Nutritional Information==== */

.nutritional-info {
    margin-top: 100px;
}

.nuture-container {
    background-color: rgba(0, 0, 0, 0.06);
    padding-top: 25px;
}

.nutritional-info h4 {
    margin-left: 100px;
}

.nature-item-title {
    display: flex;
    text-decoration: none;
    color: black;
    font-weight: 700;
    font-size: 20px;
    margin-top: 8px;
    cursor: pointer;
}

.nature-item-title:hover {
    color: #000;
}

.natureitem-title {
    text-decoration: none;
    font-size: 30px;
    font-weight: 600;
    color: #000;
    cursor: pointer;
    padding-inline-end: 50px;
}

.natureitem-title:hover {
    color: #000;
}

.value-box {
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgb(137 137 137 / 26%);
    margin-block: 15px;
    padding: 10px;
    border-radius: 10px;
}

.value-box h5 {
    font-size: 28px;
}

.value-box button {
    background-color: #d8d8d8;
    border: none;
    white-space: nowrap;
    width: 100%;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 5px;
    padding-block: 5px;
}

.value-btn {
    background-color: #d8d8d8 !important;
    border: none !important;
    white-space: nowrap;
    width: 100%;
    font-size: 13px !important;
    border-radius: 5px !important;
    margin-top: 5px;
    padding-block: 5px;
    font-weight: 400 !important;
    color: #000 !important;
    text-transform: capitalize !important;
    text-align: center;
}

.value-btn a {
    color: #000 !important;
    padding-inline: 0;
    padding-block: 10px;
    font-weight: 600;
}

.nav-pills .nav-link.active {
    background-color: var(--blue) !important;
    color: #fff !important;
}

.value-table tr td {
    padding-block: 3px;
    border: none;
}

.value-table tr td:last-child {
    text-align: end;
}

.vlaue-detail h6 {
    color: var(--blue);
    text-align: center;
    font-weight: 700;
}

.suitable-item {
    font-size: 16px;
    font-weight: 700;
}

.value-box a {
    color: var(--blue);
    text-decoration: none;
    cursor: pointer;
}

.value-box a:hover {
    color: var(--blue);
}

.Toastify__toast-container {
    margin-top: 70px;
}

.panel-body {
    width: 80%;
    margin: 0 auto;
}

.track-container {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
}

/* ====Survay Page==== */
.survey-bar {
    width: 100%;
    margin-top: 70px;
    background-color: #353535;
    text-align: center;
    padding-block: 10px;
}

.survey-bar h6 {
    color: #FFFFFF;
    margin-bottom: 0;
    font-size: 17px;
}

.survey-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.survey-container div {
    padding: 5px 15px;
    border: 2px solid #797373;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    background-color: #fff;
}

.survay-page h4 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
}

.survey-btn {
    text-align: center;
}

.survey-btn button {
    background: var(--blue);
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    margin-top: 20px;
    padding: 10px 40px;
}

.survey-option-background {
    background: var(--blue) !important;
}

.fulladdress {
    opacity: 0.5 !important;
}

.btn-item {
    background-color: transparent;
    border: none;
    color: #e41837;
    font-size: 20px;
    right: 10px;
    top: 51%;
    position: absolute;
    transform: translateY(-50%);
}

.outer-order-type {
    display: flex;
    justify-content: space-around;
    margin-top: 12px;
    padding-top: 80px;
}

.custom-width {
    width: 50%;
    margin: auto;
    margin-bottom: 12px;
    border-radius: 14px;
}

.scrollModify {
    max-height: calc(calc(80vh - (24px * 2) - (73px + 73px)) - 50px);

    overflow-y: auto;
}

[data-toggle="collapse"].acordeon-toggle:after {
    content: '/`\\';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #1A1A1A;
    transition: all 0.5s;
}


[data-toggle="collapse"].collapsed:after {


    color: #1A1A1A;
    content: " \\./";
    padding-bottom: 4px;
    padding-top: 4px;
    border-radius: 50%;
}

.act2 {
    border-color: transparent;
    box-shadow: rgb(0 0 0 / 15%) 0px 0.5px 10px 0px;
    font-size: 18px;
    font-weight: 700;
    padding-block: 12px;
    border-radius: 15px !important;
    text-transform: capitalize !important;
    background: white;
}

.act {
    border-color: transparent;
    box-shadow: rgb(0 0 0 / 15%) 0px 0.5px 10px 0px;
    font-size: 18px;
    font-weight: 700;
    padding-block: 12px;
    color: white;
    border-radius: 15px !important;
    text-transform: capitalize !important;
    background: var(--blue);
}

/* ====Skeleton Loader Page==== */
@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.timeline-item {
    padding: 2rem;
    /* border: 2px solid #f2f2f2; */
    /* border-radius: 16px; */
    /* background: #fff; */
    position: relative;
    z-index: 1;
    cursor: pointer;
    width: 100%;
    min-height: 200px;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }
}

.animated-background {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 154px;
    position: relative;
    border-radius: 16px;
}

.background-masker {
    background: #fff;
    position: absolute;
}

/* Every thing below this is just positioning */

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
    top: 0;
    /* left: 40px; */
    right: 0;
    height: -8px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
    top: 10px;
    left: 40px;
    height: -1px;
    width: 10px;
}

.background-masker.header-bottom {
    top: 18px;
    height: -4px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
    top: 24px;
    height: 0px;
}


.background-masker.header-right,
.background-masker.subheader-right {
    width: auto;
    left: 300px;
    right: 0;
    height: -13px;
}

.background-masker.subheader-right {
    left: 230px;
}

.background-masker.subheader-bottom {
    top: 30px;
    height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
    top: 40px;
    left: 0;
    right: 0;
    height: 0px;
}

.background-masker.content-top {
    height: 0px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
    width: auto;
    left: 380px;
    right: 0;
    top: 60px;
    height: 8px;
}

.background-masker.content-second-line {
    top: 68px;
}

.background-masker.content-second-end {
    left: 420px;
    top: 74px;
}

.background-masker.content-third-line {
    top: 82px;
}

.background-masker.content-third-end {
    /* left: 300px; */
    top: 88px;
}

.order-title {
    width: 90%;
    font-weight: 700;
}

.order-item-price {
    font-weight: 700;
    margin-top: 10px;
}


.mainue-scroll {
    background-color: #4a4a4a;
    width: 100%;
}

.menue-navbar .main-header {
    position: inherit !important;
}


.error-404 {
    height: 99vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.error-404 h4 {
    font-size: 35px;
    font-weight: 800;
    text-transform: uppercase !important;
    font-family: cursive;
    text-align: center;
}

.error-404 a {
    background: rgb(0, 105, 145);
    background: linear-gradient(257deg, rgba(0, 105, 145, 1) 2%, rgba(228, 24, 55, 1) 58%);
    color: #fff !important;
    text-decoration: none;
    padding: 11px 20px;
    font-weight: 600;
    border-radius: 9px;
    margin-block: 25px;
    position: relative;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.error-404 a:before,
.error-404 a:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    top: 0;
    bottom: 0;
    left: 10px;
    right: 10px;
    -moz-border-radius: 100px / 10px;
    border-radius: 100px / 10px;
}

.error-404 a:after {
    right: 10px;
    left: auto;
    -webkit-transform: skew(8deg) rotate(3deg);
    -moz-transform: skew(8deg) rotate(3deg);
    -ms-transform: skew(8deg) rotate(3deg);
    -o-transform: skew(8deg) rotate(3deg);
    transform: skew(8deg) rotate(3deg);
}

.error-404 h5 {
    font-size: 16px;
    font-weight: 600;
    width: 40%;
    text-align: center;
}

.error-image {
    width: 350px;
}

/* .radio-image {
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
    height: 60px;
    border: 5px solid #e41837 !important;
} */

.image-radio-box{
    width: 75px;
    height: 75px;
    display: flex;
    margin: 0 0 5px;
    border-radius: 50%;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}

.image-radio {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    object-fit: cover;
    /* border: 5px solid rgb(119, 119, 119); */
    /* padding: 3px; */
}

.pizza-size-title {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.pizza-size-serving {
    font-size: 14px;
    text-align: center;
    color: var(--blue);
}

/* ===radio small=== */
[type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* IMAGE STYLES */
[type=radio]+img {
    cursor: pointer;
    margin-left: 10px;
}

/* CHECKED STYLES */
[type=radio]:checked+img {
    outline: 3px solid #f00;

}

[type=radio][id="emojiYellow"]:hover+img {
    -webkit-filter: drop-shadow(0px 5px 5px #ffce00);
    filter: drop-shadow(0px 5px 5px #ffce00);
}

[type=radio][id="emojiRed"]:hover+img {
    -webkit-filter: drop-shadow(0px 5px 5px #dd3e07);
    filter: drop-shadow(0px 5px 5px #dd3e07);
}

[type=radio][id="emojiYellow"]:checked+img {
    outline: 3px solid #FBD043;
    padding: 5px;
    border-radius: 50%;
}

[type=radio][id="emojiRed"]:checked+img {
    outline: 3px solid #dd3e07;
    padding: 5px;
    border-radius: 50%;
}

.stor-slection {
    height: 64vh;
    margin-top: 10px;
    overflow-y: auto;
    position: relative;
    overflow-x: hidden;
}

.nut-title {
    overflow: hidden;
    margin-left: 10px;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    width: 90%;
}

/* ====Legend page=== */
.main-div {
    position: relative;
}

.legend-title button {
    font-size: 24px !important;
}

.legend-images {
    width: 40px;
}

.legend-images-2 {
    width: 28px;
    margin-right: 10px;
}

.legend-img-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.ledend-main .voucher-body {
    border-top: none !important;
}

.ledend-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.legend-detail {
    width: 98%;
    margin: 0 auto;
}


.legend-detail h5 {
    font-size: 17px;
    font-weight: 700;
    /* margin-top: 20px; */
    margin-bottom: 0;
}

.add-legend {
    width: 30px;
}

.add-legend-item {
    display: flex;
    position: absolute;
    z-index: 1;
    gap: 5px;
    top: 8.5rem;
    right: 10px;
}

.add-legend-item img {
    width: 20px;
}

.legend-option {
    gap: 10px;
    top: -65px;
    right: 5px;
    display: flex;
    margin-right: 15px;
    position: absolute;
    margin-bottom: 10px;
    justify-content: end;
}

.product-container {
    position: relative;
}

.piza-option {
    display: flex;
    justify-content: space-around;
}

.select-voucher .nav-item {
    margin-top: 0;
    padding: 5px;
}

.select-voucher .nav-item button {
    margin-top: 0;
    padding: 5px 65px;
    border-radius: 5px;
    background-color: #eeee;
    color: black;
    font-weight: 500;
}

/* .fade .product-modal .modal .show {
    z-index: 9999 !important;
} */
.Toastify__toast-container {
    z-index: 99999 !important;
}

.Flavours .nav-tabs .nav-link.active {
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
    color: white !important;
}

.choice1stHalf {
    display: none !important;
}

.choice2ndHalf {
    font-size: 10px !important;
    margin-top: 15px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.address-location div table {
    width: 100% !important;
}

.confirm-address table {
    width: 100% !important;
}

.ComboTabs .nav-item {
    font-size: 16px !important;
    font-weight: 800 !important;
    text-transform: uppercase !important;
    padding: 5px;
    margin-top: 10px;
}

.ComboTabs .nav-item button {
    margin-top: 0;
    padding: 5px 25px;
    border-radius: 5px;
    background-color: #eeee;
    color: black;
    font-weight: 500;
}

.ComboTabs .nav-tabs .nav-link.active {
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
    color: white !important;
}

.tab-optionnal {
    background-color: white;
    padding-block: 10px;
    margin-bottom: 10px;
    padding-inline: 5px;
    border-radius: 10px;
}

.offer-card-heading-text {
    color: #fff;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    border-radius: 7px;
    align-items: center;
    background-color: rgb(228, 24, 55);
}

.offer-card-heading-text span{
    padding: 5px 20px;
}

.offer-card-heading-text strong{
    color: #fff;
    flex-shrink: 0;
    font-weight: 700;
    padding: 5px 10px;
    background-color: rgb(33, 37, 41)    ;
}   

.cart-quantity-badge {
    display: block;
    padding: 2px 5px;
    position: absolute;
    top: 10px;
    right: 18px;
    background-color: #e31837;
    border-radius: 50%;
    font: 700 10px/1.25em Arial, Helvetica, sans-serif;
    text-shadow: none;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .4);
    text-align: center;
    color: white;
    line-height: normal;
}

.mobile-cart-quantity-badge {
    display: block;
    padding: 2px 5px;
    position: absolute;
    top: -2px;
    right: 5px;
    background-color: #e31837;
    border-radius: 50%;
    font: 700 10px/1.25em Arial, Helvetica, sans-serif;
    text-shadow: none;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .4);
    text-align: center;
    color: white;
    line-height: normal;
}

.tab-optionnal .nav-item {
    padding: 0px 5px !important;
}

/* .offers-titles {
    margin-top: 100px !important;
} */

.grand-total {
    padding-bottom: 20px;
}

.clr-white {
    color: #fff !important;
}

.trash-btn {
    color: #fff !important;
    font-size: 16px !important;
}

.suggested-header {
    font-size: 11px;
    font-weight: 600;
}

.suggested-btn {
    border: none;
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    background-color: var(--blue);
    border-radius: 5px;
    color: #fff;
}

.suggest-items-sticky {
    position: fixed;
    top: 5px;
    right: 0;
    width: 30%;
    margin-right: 15px;
}

.suggest-items-mian {
    position: fixed;
    top: 165px;
    /* right: 0; */
    width: 18%;
    margin-right: 15px;
    /* margin-left: 24px; */
    transition: all 1s;
}

.suggest-items-mian-p {
    display: none;
}


.suggested-price {
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 700;
    color: rgb(214, 0, 0);
}

.suggestive-mobile {
    display: none;
}

.suggestive-mobile-title {
    font-size: 16px;
    font-weight: 700;
}

.suggested-items {
    width: 5rem;
}

.topping-price {
    font-size: 14px;
}

.menue-navbar {
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0;
}

.offer-mian {
    margin-top: 150px;
}

/* .abActive{
    font-size: 30px;
} */

/* .second-item ul button:last-child{
color: red !important;
font-size: 10px !important;
} */


.re-order {
    font-size: 14px;
    background-color: var(--blue);
    padding: 5px;
    color: white;
    border-radius: 5px;
    width: 130px;
    text-align: center;
    display: flex;
    margin: 0 auto;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    cursor: pointer;
}

.re-order i {
    margin-left: 5px;
    color: #fff;
    font-size: 18px;
}

.address-detail {
    word-break: break-all;
}

/* ===== */
.banner-container {
    width: 75% !important;
    margin: 0 auto;
}

.today-offer {
    background: #ededed;
    position: relative;
}

.today-check {}

.today-check input {
    width: 25px;
    height: 25px;
}

.today-btn {
    display: flex;
    gap: 5px;
}

.today-btn button {
    width: 100%;
    border: none;
    padding: 7px;
    background-color: var(--blue);
    margin-top: 16px;
    border-radius: 5px;
    color: #fff;
}

.bg-demo {
    background-color: rgb(0, 105, 145) !important;
}

.delevery-check {
    word-break: break-all;
    padding-inline: 20px;
}

.address-saves {
    max-height: 256px;
    overflow: auto;
    padding: 10px;
}

.index-btn {
    z-index: 9;
}

.go-back {
    color: var(--blue);
    font-size: 22PX;
    font-weight: 700;
    width: 35px;
    height: 35px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid var(--blue);
    transition: all 0.3s;
    text-decoration: none;
}

.go-back:hover {
    background-color: var(--blue);
    color: #fff;
    cursor: pointer;
}

.back-mobile {
    display: none !important;
}

.add-address-location {
    background-color: lightgray;
}

.number-Otp input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.number-Otp input[type=number] {
    -moz-appearance: textfield;
}

.appsoore-mobile-qr {
    display: none;
}

.pass-hideicon {
    position: relative;
}

.hideicon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.facebookicon {
    background: #1877F2 !important
}

.youtubeicon {
    background: #FF0000 !important
}

.twittericon {
    /* background: #1DA1F2 !important */
    background-color: black !important;
}

.instaicon {
    background: #E4405F !important
}

.icon:hover {
    background-color: transparent !important;
}

.skip-for-menu {
    display: none;
    text-align: center;
    font-weight: 500;
    color: #000;
}

.iconTwiiter:hover {
    background-color: transparent !important;
    color: black !important;
}

.iconTwiiterIcon:hover {
    font-size: 24px !important;
}

.disableShadow {
    opacity: 0.7;
    /* Make the div semi-transparent to indicate that it's disabled */
    cursor: not-allowed;
    pointer-events: none;
    /* Disable pointer events on the div */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.mt-banner-add {
    margin-top: 3.1%;
}

#simplex-maps-parent-container .client-side-map-container .go-to-location-wrapper {
    top: 200px;
}

#simplex-maps-parent-container .client-side-map-container .maps-zoom-wrapper {
    top: 235px;
}

#simplex-maps-parent-container .client-side-map-container #feedback-container {
    top: 265px;
    display: none;
}

#simplex-maps-parent-container .client-side-map-container .text-maps-primary {
    color: var(--blue)
}

#simplex-maps-parent-container .client-side-map-container .leaflet-popup-content {
    margin: 5px 20px 5px 20px;
}

#simplex-maps-parent-container .client-side-map-container .leaflet-popup-content-wrapper {
    background-color: var(--blue);
    color: white;
    text-align: center;
}

#simplex-maps-parent-container .client-side-map-container .leaflet-popup {
    display: block;
}

#simplex-maps-parent-container .client-side-map-container .leaflet-popup-tip {
    background-color: var(--blue);
}

/* removing the marker because its causing buggy behaviour  */
.modal-content .modal-body .new-address .row img:not(#address-marker) {
    display: none;
}

#address-marker {
    width: 20px;
    height: 30px;
    max-width: unset;
}

.address-marker-wrapper {
    display: grid;
    place-content: center;
}

.address-string {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 600;
}

.store-map-item-remove .maps-search-wrapper,
.store-map-item-remove .bottom-maps-widgets-inner-margin,
.store-map-item-remove .aspect-square,
.store-map-item-remove .leaflet-interactive,
.store-map-item-remove .leaflet-popup-close-button {
    display: none;
}

.store-map-item-remove .simplex-maps-custom-marker-icon {
    display: block;
    height: 40px !important;
    width: 40px !important;
}

.store-map-item-remove .leaflet-popup {
    display: block !important;
}

.explore-title {
    text-align: center;
    color: #fff;
    font-size: 40px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.explore-title span {
    color: #e41837;
}

.explore-order-now-btn {
    background-color: #e41837;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    color: #FFF;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    display: flex;
    width: auto;
    margin: 0 auto;
    margin-top: 20px;
}

.featre-categories-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    width: 100%;
    padding: 10px;
    gap: 5px;
    overflow: auto;
}

.featre-categories-container img {
    width: 20px;

}

.feature-categories {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 20px;
    border: 1px solid #d7d4d4;
    padding: 5px 10px;
    background-color: #fff;
    cursor: pointer;
}

.location-crunt-pickup {
    width: auto;
    margin: 0 auto;
    display: flex;
    border: none;
    color: #000;
    padding: 0;
    margin-bottom: 20px;
    font-weight: 500;
    border-bottom: 1px solid;
}

button.button-cart-item {
    background-color: var(--blue);
    font-size: 24px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
    border: none;
    padding: 0;
    margin: 0;
}

.add-sugestive-card button {
    font-size: 14px;
    background-color: var(--blue);
    padding: 5px;
    color: white;
    border-radius: 5px;
    width: 130px;
    text-align: center;
    display: flex;
    margin: 0 auto;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    cursor: pointer;
    border: none;
}

.card-title-suggestive {
    font-size: 16px;
    color: var(--blue);
    min-height: 18px;
    max-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-weight: 600;
}

.card-img-top {
    width: 100%;
    height: 9rem;
    object-fit: cover;
}

.suggestive-items-card {
    display: flex;
    width: 100%;
    overflow: auto;
    gap: 10px;
}

.add-sugestive-card.card {
    width: 250px;
    min-width: 250px;
}

.suggestive-item-from {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}

.suggestive-item-price {
    font-size: 14px;
    font-weight: 600;
    color: var(--blue);
    /* padding-left: 10px; */
    font-family: 'Open Sans', sans-serif;
}

.suggestive-item-discount {
    font-size: 14px;
    font-weight: 600;
    color: var(--red);
    padding-left: 7px;
    text-decoration: line-through;
    font-family: 'Open Sans', sans-serif;
}

.price-suggestive-item {
    white-space: nowrap;
    border: 2px solid var(--blue);
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 12px;
    color: var(--blue);
}

.size-img-dropdoen {
    width: 25px;
    height: 25px;
}

.dropdown-pizza-size {
    padding-inline: 10px;
}

.dropdown-pizza-size ul li {
    list-style: none;
    padding: 0;
    border-bottom: 1px solid #eee;
    padding-block: 5px;
    transition: all 0.3s;
    padding-inline: 5px;
}

.dropdown-pizza-size ul li:hover {
    background-color: #eee;
}

.dropdown-pizza-size ul {
    padding: 10px;
    background-color: #fff;
    z-index: 9;
    position: relative;
    margin-inline: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    margin-top: 8px;

}

.dropdown-pizza-size .serve-number {
    font-size: 12px;
    color: #e41837;
    font-weight: 400;
}

.dropdown-pizza-size h6 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    white-space: nowrap;
}

.dropdown-pizza-size .drop-down-box{
    display: flex;
    align-items: center;
 
}
button.manu-card-option {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 1px;
    margin-bottom: 10px;
    white-space: nowrap;
}

button.manu-card-option i {
    font-size: 16px;
}

.option-body{
    border-radius: 16px;
    background: #f7f7f7;
    padding: 12px 10px !important;
}
/* scroll to top button */
.scroll-to-top {
    opacity: 0;
    right: 20px;
    width: 50px;
    bottom: 20px;
    border: none;
    height: 50px;
    display: flex;
    z-index: 1000;
    color: white;
    cursor: pointer;
    position: fixed;
    border-radius: 50%;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    background-color: #006991;
  }
  
  .scroll-to-top.visible {
    opacity: 1;
    visibility: visible;
  }
  
  .scroll-to-top:hover {
    background-color: #1884ac;
    transform: translateY(-3px);
  }
  
  .scroll-to-top i {
    font-size: 28px;
}
.size-content span,
.size-content .card-price{
    display: block;
    padding: 0 !important;
    margin: 0 !important;
}
.offer-card-heading-box{
    gap: 10px;
}
.modal-single-item span{
    font-weight: 400;
}
.modal-single-item .description{
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px !important;
}
.no-wrap{
    white-space: nowrap;
}
.selectfile-input{
    max-width: 220px;
}