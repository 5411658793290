.apps-container {
    margin-top: 68px;
    background-color: #0c0c0c;
    padding: 20px 0;
}

.apps-title {
    color: #fff;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: -1px;
    margin-bottom: 30px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.apps-desc {
    color: #fff;
    font-size: 20px;
    font-weight:300;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.apps-wrapper {
    background-color: #0c0c0c;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* max-width: 1140px; */
    margin-top: 0;
    padding: 40px 20px;
    display: flex;
    margin: 0 auto;
    /* height: 50vh; */
}

.app-but-img {
    height: 50px
}

.apps-buts {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.apps-mockup {
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@media (min-width: 460px) { 
    .apps-buts {
        flex-direction: row;
        justify-content: center;
    }
}

@media (min-width: 576px) { 
    .apps-wrapper {
        padding: 40px 20px;
    }
    .apps-title {
        font-size: 30px;
    }
    .apps-desc {
        font-size: 20px;
    }
}

@media (min-width: 768px) { 
    .apps-container {
        padding: 0px;
    }
    .apps-wrapper {
        padding: 0px 2rem 0px 2rem;
    }
    .apps-title {
        font-size: 20px;
    }
    .apps-desc {
        font-size: 16px;
    }
    .apps-buts {
        flex-direction: column;
        align-items: center;
    }
    .app-but-img {
        height: 40px
    }
}

@media (min-width: 992px) { 
    /* .apps-wrapper {
        height: 70vh;
    } */
    .apps-title {
        font-size: 30px;
    }
    .apps-buts {
        flex-direction: row;
        justify-content: start;
    }
}

@media (min-width: 1024px) { 
    .apps-title {
        font-size: 33px;
    }
    .apps-desc {
        font-size: 18px;
    }
    .apps-wrapper {
        padding: 0px 2rem 0px 2rem;
    }
    .app-but-img {
        height: 47px
    }
}

@media (min-width: 1200px) { 
    .apps-title {
        font-size: 35px;
    }
    .apps-desc {
        font-size: 20px;
    }
    .apps-wrapper {
        padding: 0px 2rem 0px 2rem;
    }
    .app-but-img {
        height: 50px
    }
}

@media (min-width: 1450px) { 
    .apps-title {
        font-size: 45px;
    }
    .apps-desc {
        font-size: 23px;
    }
    .apps-wrapper {
        padding: 0px 4rem 0px 4rem;
    }
    .app-but-img {
        height: 55px
    }
}
 