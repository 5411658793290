:root {
    --blue: rgb(0, 105, 145);
    --green: rgb(0, 214, 0);
    --red: rgb(214, 0, 0)
}

.signup-header-image {
    width: 100%;
    height: 15vh;
    object-fit: cover;
    object-position: center 60%;
}

.signup-container {
    background-color: rgba(0, 0, 0, 0.06);
    padding-top: 50px;
}

.create-acc-button { 
    background-color: "rgb(128, 128, 128)";
    color: "white";
}

.signup-form {
    background-color: white;
    padding-block: 15px;
}

.phone-number-container {
    background-color: whitesmoke;
    padding: 10px 40px;
    border: 1px solid #ced4da;
}

#otp-input .react-tel-input .flag-dropdown {
    display: none;
}

.send-otp-button-disabled {
    text-align: center;
    background-color: #7c7c7c !important;
    border: none;
    color: white !important;
    border-radius: 10px;
    width: 355px;
    font-weight: 600;
    padding: 10px;
    width: 100%;
    text-transform: uppercase;
}

.send-otp-button-active {
    text-align: center;
    background-color: var(--blue) !important;
    border: none;
    color: white !important;
    border-radius: 10px;
    width: 355px;
    font-weight: 600;
    padding: 10px;
    width: 100%;
    text-transform: uppercase;
}

.verify-otp-button-disabled {
    text-align: center;
    background-color: #7c7c7c !important;
    border: none;
    color: white !important;
    border-radius: 10px;
    width: 355px;
    font-weight: 600;
    padding: 10px;
    width: 100%;
    text-transform: uppercase;
}

.verify-otp-button-active {
    text-align: center;
    background-color: var(--green) !important;
    border: none;
    color: white !important;
    border-radius: 10px;
    width: 355px;
    font-weight: 600;
    padding: 10px;
    width: 100%;
    text-transform: uppercase;
}

.resend-otp {
    font-size: 18px;
    font-weight: 700;
}

.otp-text {
    font-size: 20px;
    font-weight: 700;
}

.remain-text {
    font-size: 14px;
    margin-bottom: 0px;
}

.resend-otp-click {
    font-size: 18px;
    color: var(--red) !important;
    font-weight: 700;
    border: none;
    padding: 0px;
    background-color: transparent;
}

#otp-input .react-tel-input .form-control {
    border: none;
    padding: 0px !important;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#otp-input .react-tel-input .form-control:focus {
    outline: none;
    background-color: transparent !important;
}

.signin-privacy {
    font-weight: 500;
    color: #4a4a4a;
}

.singin-link {
    text-decoration: none;
    color: var(--blue)
}

@media only screen and (min-width: 425px) {
    .signup-header-image {
        height: 20vh;
    }
    .signup-form {
        padding-block: 30px;
    }
}

@media only screen and (min-width: 678px) {
    .signup-header-image {
        height: 25vh;
    }
    .signup-form {
        padding-block: 40px;
    }
}

@media only screen and (min-width: 768px) {
    .signup-header-image {
        height: 25vh;
    }
    .signup-form {
        padding-block: 40px;
    }
}

@media only screen and (min-width: 992px) {
    .signup-header-image {
        height: 35vh;
    }
}


@media only screen and (min-width: 1200px) {
    .signup-header-image {
        height: 40vh;
    }
}