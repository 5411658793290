
.extra-topping input[type="checkbox"]:checked,
.extra-topping input[type="checkbox"]:not(:checked),
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.extra-topping input[type="checkbox"]:checked + label,
.extra-topping input[type="checkbox"]:not(:checked) + label,
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  color: #000;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
  padding-left: 35px;
  display: inline-block;
  width: 100%;
}
.extra-topping input[type="checkbox"]:checked + label:before,
.extra-topping input[type="checkbox"]:not(:checked) + label:before,
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    top: 0;
    left: 0;
    content: '';
    width: 22px;
    height: 22px;
    position: absolute;
    background: #fff;
    border-radius: 100%;
    border: 2px solid #000;

}
.extra-topping input[type="checkbox"]:checked + label:before,
[type="radio"]:checked + label:before{
  border-color: #000!important;
}

.extra-topping input[type="checkbox"]:checked + label:before{
  background: #000;
}
/* .extra-topping input[type="checkbox"]:checked + label:after,
.extra-topping input[type="checkbox"]:not(:checked) + label:after, */
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  top: 4px;
  left: 4px;
  width: 14px;
  content: '';
  height: 14px;
  position: absolute;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: #000!important;
}
.extra-topping input[type="checkbox"]:checked + label:after{
  left: 7px;
  top: 3px;
  opacity: 0;
  width: 7px;
  content: '';
  height: 13px;
  position: absolute;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* .extra-topping input[type="checkbox"]:not(:checked) + label:after, */
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.extra-topping input[type="checkbox"]:checked + label:after{
  opacity: 1;
}
/* .extra-topping input[type="checkbox"]:checked + label:after, */
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
@media (max-width:425px){
  .extra-topping input[type="checkbox"]:checked + label:before,
  .extra-topping input[type="checkbox"]:not(:checked) + label:before,
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    width: 18px;
    height: 18px;
    top: 3px;
    left: 2px;
  }

  /* .extra-topping input[type="checkbox"]:checked + label:after,
  .extra-topping input[type="checkbox"]:not(:checked) + label:after, */
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 10px;
      height: 10px;
      top: 7px;
      left: 6px;
  }
  .extra-topping input[type="checkbox"]:checked + label:after,
  .extra-topping input[type="checkbox"]:not(:checked) + label:after{
    top: 6px;
    left: 8px;
    width: 6px;
    height: 10px;
  }
}

.radio-btn-style{
  gap: 5px;
  display: flex;
  padding: 16px 20px;
  background: #fff;
  border-radius: 30px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between; 
  &:last-child{
    margin-bottom: 0;
  }
}


.radio-btn-style .form-check {
  margin: 0;
  width: 100%;
  padding-left: 0;
}

/* ==Extra==== */
  .navbar {
    position: fixed;
    background-color: #6c757d;
    width: 100%;
    text-align: center;
    height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .navbar-items {
    padding: 0;
    font-size: 1.25rem;
  }
  .navbar-items li {
    list-style: none;
    display: inline-block;
  }
  .navbar-items li + li {
    margin-left: 20px;
  }
  .navbar-items li a {
    color: #ffffff;
    text-decoration: none;
    position: relative;
  }
  .navbar-items li a::after {
    width: 0;
    content: "";
    height: 4px;
    background-color: #ffc107;
    position: absolute;
    bottom: -5px;
    left: 0;
    transition: width 0.3s ease;
  }
  .navbar-items li a.active::after {
    width: 100%;
  }



  /* =========== */

  


  
  
  .extra-topping input {
    display: none;
  }
  
  /* .extra-topping input:checked + label {
    opacity: 1;
    box-shadow: 0 0 0 3px var(--background);
    border: 2px solid #e41837;
  } */
  
  /* .extra-topping input:checked + label img {
    border: 3px solid #e41837;
  } */
  
  /* .extra-topping input:checked + label .cover-checkbox {
    opacity: 1;
    transform: scale(1);
  }
  
  .extra-topping input:checked + label .cover-checkbox svg {
    stroke-dashoffset: 0;
  } */
  
  .extra-topping label {
    display: inline-block;
    cursor: pointer;
    border-radius: var(--border-radius);
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    /* opacity: 0.6; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    /* border: 1px solid #c5c5c5; */
    border-radius: 30px;
    width: 100%;
    /* padding-block: 3px;
    background-color: #f0eded;
    padding-inline-start: 5px;
    padding-inline-end: 20px; */
  }
  
  .extra-topping label img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    /* clip-path: polygon(0% 0%, 100% 0, 100% 81%, 50% 100%, 0 81%); */
    /* -webkit-filter: grayscale(100%); Safari 6.0 - 9.0 */
    /* filter: grayscale(100%); */
    border-radius: 50%;
  }
  
  .extra-topping {
    height: 24px;
    width: 100% !important;
  }

.topping-items{
  width: 100%;
  justify-content: space-between;
}
/* pizza Size  */
.size-container{
  padding: 0;
  width: 100%;
  display: flex;
  overflow-x: auto;
  align-items: center;
}

.size-container::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.size-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.size-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}


.size-container [type="radio"] + label::after{
  width: 0 !important;
  height: 0 !important;
  border: 0 !important;
}
.size-container [type="radio"] + label::before{
  width: 0 !important;  
  height: 0 !important;
  border: 0 !important;
}

.size-content{
  width: 100%;
  display: flex;
  padding: 0 5px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.size-content .form-check-label p{
  flex-direction: column;
}

.size-content .form-check{
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.size-content [type="radio"] + label{
  width: 100%;
  display: flex;
  /* min-width: 90px; */
  max-width: 150px;
  min-height: 164px;
  padding: 10px 8px;
  text-align: center;
  align-items: center;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #fff;
  transition: all 0.3s ease;
}

.size-content [type="radio"]:checked + label{
  border-color: #000;
}

/* pizz Crust tail style  */
.tailstyle{
  gap: 15px;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.tailstyle-content,
.tailstyle-content .form-check{
  margin: 0;
  width: 100%;
  padding-left: 0;
}
.tailstyle-content label,
.tailstyle-content .form-check-label{
  overflow: hidden;
  min-height: 120px;
  border-radius: 10px;
  /* background: #d1d1d1; */
  display: flex !important;
  justify-content: space-between;
  padding: 0 45px 0 0 !important;
}
.tailstyle-content [type="radio"]:not(:checked) + label{padding: 0;}
.tailstyle-content [type="radio"]:not(:checked) + label:before{
  top: 50%;
  left: auto;
  z-index: 2;
  right: 10px;
  border-color: #fff;
  background: transparent;
  transform: translateY(-50%);
}
.tailstyle-content [type="radio"]:checked + label:before{
  top: 50%;
  left: auto;
  z-index: 2;
  right: 10px;
  background: transparent;
  transform: translateY(-50%);
  border-color: #fff !important;
}
.tailstyle-content [type="radio"]:checked + label:after,
.tailstyle-content [type="radio"]:not(:checked) + label:after{
  top: 50%;
  left: auto;
  z-index: 2;
  right: 14px;
  transform: translateY(-50%);
  background: #fff !important;
}
.tailstyle-content .image-radio-box{
  margin: 0;
  width: 100%;
  height: auto;
  border-radius: 0;
  padding-right: 30%;
}
.tailstyle-content .image-radio-box img,
.tailstyle-content .image-radio-box img.image-radio{
  border-radius: 0;
  margin-left: -20px;
  width: 100% !important;
  height: 120px !important;
}
.tailstyle-content .modal-single-item{
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  color: #fff;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 42px 10px 60%;
  &::after{
    top: 0;
    left: 0;
    width: 100%;
    content: '';
    z-index: -1;
    height: 100%;
    position: absolute;
    transition: all 300ms ease-in-out;
    background: linear-gradient(90deg, rgba(0,244,254,0) 52%, #92918f 60%);
  }
}
.tailstyle-content [type="radio"]:checked + label .modal-single-item::after{
  background: linear-gradient(90deg, rgba(254,0,0,0) 52%, #e41837 60%)
}
.tailstyle-content .product-modal-more-optional,
.tailstyle-content .modal-single-item span{                                                                                                                                                                           
  font-size: 11px;
  line-height: 15px;
  color:#fff !important;
  margin-left: 0 !important;
}